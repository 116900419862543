import React from 'react';
import { Link } from 'react-router-dom';

import { AppRoute } from 'const';
import { SEO } from 'components/shared/SEO';
import { useRevealAnimation } from 'hooks';

export const ErrorPage: React.FC = () => {
  useRevealAnimation('light');

  return (
    <React.Fragment>
      <SEO
        seoTitle="404 Page not found — PROTOTYP"
        seoDescription="Uh-oh! Something went wrong. It could be you, or it could be us, but we could not find what you were looking for."
      />

      <section className="errorpage">
        <div className="errorpage__content">
          <div className="wrapper">
            <h1 className="errorpage__title t-sans-alt t-beta">
              Greetings interwebs wanderer, the requested page does not exist in
              this realm.
            </h1>
            <p>
              Since you are already here you can always go back to homepage and
              check out our <Link to={AppRoute.Homepage}>latest work</Link> or{' '}
              <Link to={AppRoute.Contact}>get in touch</Link> with the team.
            </p>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
