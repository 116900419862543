import React from 'react';
import { Link } from 'react-router-dom';

import { AppRoute } from 'const';
import { ReactComponent as LogoSvg } from 'assets/icons/logo.svg';

interface Props {
  isAltHeader: boolean;
  isNavOpen: boolean;
}

export const Logo: React.FC<Props> = ({ isAltHeader }) => {
  let logoClasses = 'logo';
  if (isAltHeader) logoClasses += ' logo--alt';

  return (
    <Link className={logoClasses} to={AppRoute.Homepage}>
      <LogoSvg />
    </Link>
  );
};
