import anime from 'animejs';
import { useEffect } from 'react';

type RevealType = 'blue' | 'light' | 'dark';

export function useRevealAnimation(type: RevealType) {
  useEffect(() => {
    anime({
      duration: 300,
      easing: 'linear',
      targets: 'body',
      backgroundColor: getRevealColor(),
    });

    function getRevealColor() {
      switch (type) {
        case 'light':
          return '#fff';
        case 'dark':
          return '#1F1F1F';
        default:
          return '#fff';
      }
    }
  }, [type]);
}
