import React from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { AppRoute } from 'const';

export const Jumpstart: React.FC = () => {
  const [jumpStartRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  return (
    <section className="jumpstart" ref={jumpStartRef}>
      <div className="wrapper">
        <div className="jumpstart__layout">
          <motion.h1
            className="t-sans-alt t-beta"
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 80 }}
            transition={{ ease: 'circOut', duration: 1 }}
          >
            We are on the lookout for great people like you. Jump-start your
            career as an{' '}
            <Link
              to={AppRoute.AngularDeveloper}
              className="anchor anchor--negative"
            >
              Angular Developer,
            </Link>{' '}
            <Link
              to={AppRoute.InterfaceDeveloper}
              className="anchor anchor--negative"
            >
              Interface Developer
            </Link>{' '}
            or a{' '}
            <Link
              to={AppRoute.ReactNativeDeveloper}
              className="anchor anchor--negative"
            >
              React Native Developer
            </Link>
            .
          </motion.h1>
        </div>
      </div>
    </section>
  );
};
