import React from 'react';
import { motion } from 'framer-motion';
import InView from 'react-intersection-observer';

import { ReactComponent as BlockquoteSvg } from 'assets/icons/blockquote.svg';

import { CaseStudyTemplate } from './CaseStudyTemplate';

export const InvestorsClubPage: React.FC = () => (
  <CaseStudyTemplate
    seoTitle="The exclusive online business marketplace: Investors Club — PROTOTYP"
    seoDescription="Online business marketplaces were ready for a change. We helped Investors Club to disrupt the market on day one."
    imagePath="https://prototyp.digital/share/case-studies-share/investors-club-cover.jpg"
  >
    <div className="wrapper">
      <header className="project__header">
        <motion.div
          className="project__header__left"
          initial={{ opacity: 0, y: 60 }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
        >
          <h1 className="t-caption--sm t-sans-alt">Investors Club</h1>
          <p className="t-alpha t-sans-alt">
            Online business marketplace that disrupted the market on day one
          </p>
        </motion.div>
        <motion.div
          className="project__header__right"
          initial={{ opacity: 0, y: 60 }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
        >
          <a
            href="https://investors.club/"
            className="btn btn--xlrg btn--secondary"
          >
            See it live
            <span className="btn__arrow" />
          </a>
        </motion.div>
      </header>
    </div>

    <InView triggerOnce={true} threshold={0.3}>
      {({ ref, inView }) => (
        <motion.picture
          className="project__visual project__visual--fullwidth"
          ref={ref}
          initial={{ opacity: 0, y: 60 }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : 60,
          }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.8 }}
        >
          <source
            srcSet={require('assets/images/case-studies/investors-club/investors-club-cover.webp')}
            type="image/webp"
          />
          <source
            srcSet={require('assets/images/case-studies/investors-club/investors-club-cover.jpg')}
            type="image/jpeg"
          />
          <img
            loading="lazy"
            src={require('assets/images/case-studies/investors-club/investors-club-cover.jpg')}
            alt="Investors Club"
          />
        </motion.picture>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.6}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <section className="project__intro">
            <motion.div
              className="project__intro__heading"
              ref={ref}
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
            >
              <h2 className="t-beta t-sans-alt">
                Web App with exclusive private access to buy growing online
                businesses.
              </h2>
              <a href="https://www.producthunt.com/posts/investors-club">
                <picture
                  className="s-top--xlrg"
                  style={{
                    width: '172px',
                  }}
                >
                  <source
                    srcSet={require('assets/images/case-studies/investors-club/investors-club-producthunt.webp')}
                    type="image/webp"
                  />
                  <source
                    srcSet={require('assets/images/case-studies/investors-club/investors-club-producthunt.jpg')}
                    type="image/jpeg"
                  />
                  <img
                    loading="lazy"
                    src={require('assets/images/case-studies/investors-club/investors-club-producthunt.jpg')}
                    alt="Investors Club - #4 Product of the Month on Product Hunt"
                  />
                </picture>
              </a>
            </motion.div>
            <div className="project__intro__content" />
          </section>
        </div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.2}>
      {({ ref, inView }) => (
        <motion.div
          className="wrapper"
          ref={ref}
          initial={{ opacity: 0, y: 60 }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : 60,
          }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
        >
          <picture className="project__visual">
            <source
              srcSet={require('assets/images/case-studies/investors-club/investors-club-screen-1.webp')}
              type="image/webp"
            />
            <source
              srcSet={require('assets/images/case-studies/investors-club/investors-club-screen-1.jpg')}
              type="image/jpeg"
            />
            <img
              loading="lazy"
              src={require('assets/images/case-studies/investors-club/investors-club-screen-1.jpg')}
              alt="Investors Club"
            />
          </picture>
        </motion.div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.5}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <section className="project__section">
            <motion.div
              className="project__section__heading"
              ref={ref}
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
            >
              <h1 className="t-beta t-sans-alt">Flip the market on its head</h1>
            </motion.div>
            <div className="project__section__layout1">
              <motion.div
                className="project__section__layout1__left"
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
              >
                <p className="t-gamma t-sans-alt">
                  In 2019 Alpha Investors founder and CEO has had enough. The
                  way online businesses were bought and sold had to be
                  disrupted.
                </p>
              </motion.div>
              <motion.div
                className="project__section__layout1__right"
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.8 }}
              >
                <p className="t-epsilon t-sans-alt">
                  The problem was, all current marketplaces that sell online
                  businesses were lulled into the late '90s. Neither sellers nor
                  buyers were happy. Alpha Investors wanted to build something
                  that fits the needs of both. A true opportunity opened up for
                  market disruption.
                </p>
              </motion.div>
            </div>
          </section>
        </div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.3}>
      {({ ref, inView }) => (
        <motion.picture
          className="project__visual project__visual--fullwidth"
          ref={ref}
          initial={{ opacity: 0, y: 60 }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : 60,
          }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
        >
          <source
            srcSet={require('assets/images/case-studies/investors-club/investors-club-screen-2.webp')}
            type="image/webp"
          />
          <source
            srcSet={require('assets/images/case-studies/investors-club/investors-club-screen-2.jpg')}
            type="image/jpeg"
          />
          <img
            loading="lazy"
            src={require('assets/images/case-studies/investors-club/investors-club-screen-2.jpg')}
            alt="Investors Club"
          />
        </motion.picture>
      )}
    </InView>

    <InView triggerOnce={true} threshold={1}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <section className="project__section">
            <motion.div
              className="project__section__heading"
              ref={ref}
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
            >
              <h1 className="t-beta t-sans-alt">
                How to disrupt an old market?
              </h1>
            </motion.div>
            <div className="project__section__layout1">
              <motion.div
                className="project__section__layout1__left"
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
              >
                <p className="t-gamma t-sans-alt">
                  The idea was simple: Take everything you hate about other
                  brokers and fix it. It set the course for the whole project.
                </p>
              </motion.div>
              <motion.div
                className="project__section__layout1__right"
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.8 }}
              >
                <p className="t-epsilon t-sans-alt">
                  The challenge was to design the user experience around those
                  USP's and direct the digital identity onto a new path to
                  create a new product category.
                </p>
              </motion.div>
            </div>
            <ul className="project__checklist t-epsilon t-sans-alt">
              <motion.li
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 1.2 }}
              >
                <div className="project__checklist__checkmark">
                  <img src={require('assets/icons/checkmark.svg')} alt="" />
                </div>
                <p>No purchase fees - Sellers get the 100%</p>
              </motion.li>
              <motion.li
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 1.4 }}
              >
                <div className="project__checklist__checkmark">
                  <img src={require('assets/icons/checkmark.svg')} alt="" />
                </div>
                <p>Actionable and in-depth due diligence reports</p>
              </motion.li>
              <motion.li
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 1.6 }}
              >
                <div className="project__checklist__checkmark">
                  <img src={require('assets/icons/checkmark.svg')} alt="" />
                </div>
                <p className="t-epsilon">
                  {' '}
                  Exclusive and profitable assets only
                </p>
              </motion.li>
              <motion.li
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 1.8 }}
              >
                <div className="project__checklist__checkmark">
                  <img src={require('assets/icons/checkmark.svg')} alt="" />
                </div>
                <p>Assisted purchasing & personalized asset deal offerings</p>
              </motion.li>
            </ul>
          </section>
        </div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.3}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <motion.picture
            className="project__visual"
            ref={ref}
            initial={{ opacity: 0, y: 60 }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : 60,
            }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
          >
            <source
              srcSet={require('assets/images/case-studies/investors-club/investors-club-screen-4.webp')}
              type="image/webp"
            />
            <source
              srcSet={require('assets/images/case-studies/investors-club/investors-club-screen-4.jpg')}
              type="image/jpeg"
            />
            <img
              loading="lazy"
              src={require('assets/images/case-studies/investors-club/investors-club-screen-4.jpg')}
              alt="Investors Club"
            />
          </motion.picture>
        </div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={1}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <section className="project__section">
            <motion.div
              className="project__section__heading"
              ref={ref}
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6 }}
            >
              <h1 className="t-beta t-sans-alt">
                Product launch&thinsp;&mdash;&thinsp;a success story
              </h1>
            </motion.div>
            <div className="project__section__layout1">
              <motion.div
                className="project__section__layout1__left"
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
              >
                <p className="t-gamma t-sans-alt">
                  The investors.club gained traction from the get-go. Now that
                  we got real results, our focus shifted onto listening to user
                  feedback and improving existing features as well as scaling
                  the app while continuously introducing new features.
                </p>
              </motion.div>
              <motion.div
                className="project__section__layout1__right"
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.8 }}
              >
                <p className="t-epsilon t-sans-alt">
                  All of these positive results validated the product as the
                  community response was overwhelmingly positive and supportive.
                  Investors Club is{' '}
                  <a href="https://www.investors.club" className="t-link--text">
                    live
                  </a>{' '}
                  and growing each day, now you can become an investor easily!
                </p>
              </motion.div>
            </div>
          </section>
        </div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.2}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <ul className="project__keypoints">
            <motion.li
              ref={ref}
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
            >
              <span className="t-beta t-sans-alt">#4</span>
              <p className="t-epsilon t-sans-alt">
                <a
                  href="https://www.producthunt.com/posts/investors-club"
                  className="t-link--text"
                >
                  Producthunt.com
                </a>{' '}
                #4 product of the month
              </p>
            </motion.li>
            <motion.li
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
            >
              <span className="t-beta t-sans-alt">5/5</span>
              <p className="t-epsilon t-sans-alt">
                5/5 rating based on 11 reviews
              </p>
            </motion.li>
            <motion.li
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
            >
              <span className="t-beta t-sans-alt">1.5 k</span>
              <p className="t-epsilon t-sans-alt">
                Over 1.5k upvotes in the first month of presence
              </p>
            </motion.li>
          </ul>
        </div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.6}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <motion.div
            className="project__quote"
            ref={ref}
            initial={{ opacity: 0, y: 60 }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : 60,
            }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
          >
            <div className="project__quote__icon">
              <BlockquoteSvg />
            </div>
            <blockquote>
              <p className="t-beta t-sans-alt">
                I trust the PROTOTYP team to bring my idea to the market,
                especially for a complex product that hasn't been built yet.
              </p>
              <cite className="t-caption--sm t-sans-alt">
                &mdash;&thinsp;A. Ilisin, CEO, Alpha Investors
              </cite>
            </blockquote>
          </motion.div>
        </div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.3}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <section className="project__meta">
            <div className="project__meta__layout">
              <motion.div
                ref={ref}
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
              >
                <h2 className="t-delta t-sans-alt">Platforms</h2>
                <ul className="t-epsilon">
                  <li>Web</li>
                </ul>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
              >
                <h2 className="t-delta t-sans-alt">Deliverables</h2>
                <ul className="t-epsilon">
                  <li>Product strategy</li>
                  <li>Product design (UI / UX)</li>
                  <li>Software Architecture&thinsp;&amp;&thinsp;Consulting</li>
                  <li>Product development</li>
                  <li>Growth support</li>
                </ul>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
              >
                <h2 className="t-delta t-sans-alt">Technology</h2>
                <ul className="t-epsilon">
                  <li>React</li>
                  <li>TypeScript</li>
                  <li>Firebase</li>
                </ul>
              </motion.div>
            </div>
          </section>
        </div>
      )}
    </InView>
  </CaseStudyTemplate>
);
