import React from 'react';

import { SEO } from 'components/shared';
import { useRevealAnimation } from 'hooks';

import { motion } from 'framer-motion';
import { CompanyInfo } from 'components/company/fragments';

export const ContactPage: React.FC = () => {
  useRevealAnimation('light');

  return (
    <React.Fragment>
      <SEO
        seoTitle="Contact us — PROTOTYP"
        seoDescription="Get in touch with our team and start your next journey to the digital wilderness with PROTOTYP."
      />
      <section className="contact">
        <div className="wrapper">
          <header className="contact__header">
            <motion.h1
              className="t-huge t-sans-alt"
              animate={{ opacity: [0, 1], y: [60, 0] }}
              transition={{ ease: 'circOut', duration: 1 }}
            >
              Say{' '}
              <a
                className="contact__link t-sans-alt"
                href="mailto:hello@prototyp.digital"
              >
                hello<span>@prototyp.digital</span>
              </a>{' '}
              and let’s talk next steps.
            </motion.h1>
          </header>
          <div className="contact__body">
            <motion.p
              className="t-epsilon"
              animate={{ opacity: [0, 1], y: [60, 0] }}
              transition={{ ease: 'circOut', duration: 1, delay: 0.4 }}
            >
              Once we get your inquiry our team will set up a discovery
              introduction meeting to talk about your product and business
              needs, no strings attached.
            </motion.p>
            <motion.p
              className="t-epsilon"
              animate={{ opacity: [0, 1], y: [60, 0] }}
              transition={{ ease: 'circOut', duration: 1, delay: 0.6 }}
            >
              You can expect a reply to your inquiry within one business day.
              While you wait feel free to check out our blog{' '}
              <a
                href="https://blog.prototyp.digital/"
                className="anchor anchor--text"
              >
                Prototyped
              </a>{' '}
              or view our design experiments on{' '}
              <a
                href="https://dribbble.com/prototyp"
                className="anchor anchor--text"
              >
                Dribbble.
              </a>
            </motion.p>
          </div>
        </div>
        <CompanyInfo />
      </section>
    </React.Fragment>
  );
};
