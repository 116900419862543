import React from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { AppRoute } from 'const';

interface Props {
  route: AppRoute;
  client: string;
  title: string;
  numerator: string;
  category?: string;
  description?: string;
  imgSrc: string;
  imgSrcSet?: string;
  imgAlt?: string;
  bgColor?: string;
  btnColor?: string;
}

export const CaseStudyItem: React.FC<Props> = ({
  route,
  client,
  title,
  numerator,
  category,
  description,
  imgSrc,
  imgSrcSet,
  imgAlt,
  bgColor,
  btnColor,
}) => {
  const [itemRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  return (
    <article
      ref={itemRef}
      className={`case-study__item case-study__item--${bgColor}`}
    >
      <div className="wrapper">
        <header className="case-study__item__header">
          <div className="case-study__item__header__top">
            <motion.div
              className="case-study__item__header__numerator t-sans-alt t-delta"
              animate={{ opacity: inView ? 0.4 : 0, y: inView ? 0 : 40 }}
              transition={{ ease: 'circOut', duration: 0.6 }}
            >
              {numerator}
            </motion.div>
            <div className="case-study__item__header__title">
              <motion.h1
                className="t-beta t-sans-alt"
                animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
              >
                {title}
              </motion.h1>
            </div>
            <motion.div
              className="case-study__item__header__action"
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
            >
              <Link
                to={route}
                className={`actionbutton actionbutton--${btnColor}`}
              >
                {description || (
                  <span>
                    <svg
                      width="26"
                      height="10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 1l4 4-4 4M0 5h24" strokeWidth="2" />
                    </svg>
                    <svg
                      width="26"
                      height="10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 1l4 4-4 4M0 5h24" strokeWidth="2" />
                    </svg>
                  </span>
                )}
              </Link>
            </motion.div>
          </div>
          <motion.div
            className="case-study__item__header__meta"
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 0.9 }}
          >
            <p className="t-sans-alt t-caption--sm">{client}</p>
            {category && <p className="t-sans-alt t-caption--sm">{category}</p>}
          </motion.div>
        </header>
        <div className="case-study__item__visual">
          <motion.div
            className="curtain"
            animate={{ height: inView ? 0 : '100%' }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 0.9 }}
          />
          <picture>
            <source media="(min-width: 544px)" srcSet={imgSrc} />
            <source media="(max-width: 543px)" srcSet={imgSrcSet} />
            <img alt={imgAlt} src={imgSrc} />
          </picture>
        </div>
      </div>
    </article>
  );
};
