import React from 'react';

import { SEO, Articles } from 'components/shared';
import { useRevealAnimation } from 'hooks';

import { Hero, CaseStudies, Dribbble } from './fragments';
import { CTA } from './fragments/CTA';
import { Relay } from './fragments/Relay';
import { Jumpstart } from './fragments/Jumpstart';

export const HomePage: React.FC = () => {
  useRevealAnimation('dark');

  return (
    <React.Fragment>
      <SEO
        seoTitle="Products done right. — PROTOTYP"
        seoDescription="Digital product company that designs, builds, and ships market-fit products for startups and enterprises."
      />
      <Hero />
      <div id="caseStudies">
        <CaseStudies />
      </div>
      <Jumpstart />
      <Dribbble />
      <Articles />
      <Relay />
      <CTA />
    </React.Fragment>
  );
};
