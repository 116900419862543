import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

export const CompanyInfo: React.FC = () => {
  const [companyInfoRef, companyInfoRefinView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  return (
    <section ref={companyInfoRef} className="companyinfo">
      <div className="wrapper">
        <div className="companyinfo__wrapper">
          <header className="companyinfo__header">
            <motion.h1
              className="t-gamma t-sans-alt"
              animate={{
                opacity: companyInfoRefinView ? 1 : 0,
                y: companyInfoRefinView ? 0 : 80,
              }}
              transition={{ ease: 'circOut', duration: 0.6 }}
            >
              General info
            </motion.h1>
          </header>
          <div className="companyinfo__information">
            <motion.div
              className="companyinfo__card"
              animate={{
                opacity: companyInfoRefinView ? 1 : 0,
                y: companyInfoRefinView ? 0 : 80,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
            >
              <h2 className="t-epsilon t-sans-alt">General</h2>
              <ul className="companyinfo__list t-zeta">
                <li>PROTOTYP d.o.o.</li>
                <li>Kordunska 12</li>
                <li>31000 Osijek, Croatia</li>
              </ul>
            </motion.div>
            <motion.div
              className="companyinfo__card"
              animate={{
                opacity: companyInfoRefinView ? 1 : 0,
                y: companyInfoRefinView ? 0 : 80,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.5 }}
            >
              <h2 className="t-epsilon t-sans-alt">Bank account</h2>
              <ul className="companyinfo__list t-zeta">
                <li>Privredna Banka Zagreb d.d.:</li>
                <li>HR9523400091110780051</li>
              </ul>
            </motion.div>
            <motion.div
              className="companyinfo__card"
              animate={{
                opacity: companyInfoRefinView ? 1 : 0,
                y: companyInfoRefinView ? 0 : 80,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
            >
              <h2 className="t-epsilon t-sans-alt">VAT</h2>
              <ul className="companyinfo__list t-zeta">
                <li>OIB: 69410380024</li>
                <li>Vat ID: HR69410380024</li>
              </ul>
            </motion.div>
            <motion.div
              className="companyinfo__card"
              animate={{
                opacity: companyInfoRefinView ? 1 : 0,
                y: companyInfoRefinView ? 0 : 80,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.7 }}
            >
              <h2 className="t-epsilon t-sans-alt">Board</h2>
              <ul className="companyinfo__list t-zeta">
                <li>Andrej Mlinarevic</li>
                <li>Vlatko Vlahek</li>
              </ul>
            </motion.div>
            <motion.div
              className="companyinfo__card"
              animate={{
                opacity: companyInfoRefinView ? 1 : 0,
                y: companyInfoRefinView ? 0 : 80,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.8 }}
            >
              <h2 className="t-epsilon t-sans-alt">Capital equity:</h2>
              <ul className="companyinfo__list t-zeta">
                <li>100.000,00 kn paid in full</li>
              </ul>
            </motion.div>
            <motion.div
              className="companyinfo__card"
              animate={{
                opacity: companyInfoRefinView ? 1 : 0,
                y: companyInfoRefinView ? 0 : 80,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.9 }}
            >
              <h2 className="t-epsilon t-sans-alt">Reg. court</h2>
              <ul className="companyinfo__list t-zeta">
                <li>Registration: 21.03.2016.</li>
                <li>at Commercial Court in Osijek</li>
                <li>Registration number: Tt-16/2345-2</li>
              </ul>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};
