import { motion } from 'framer-motion';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

import { SEO } from 'components/shared';
import { AppRoute } from 'const';
import { useRevealAnimation } from 'hooks';

import { Position } from './fragments';

export const OpeningsPage: React.FC = () => {
  useRevealAnimation('light');

  const [starterKitRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  return (
    <React.Fragment>
      <SEO
        seoTitle="Careers and openings — PROTOTYP"
        seoDescription="We are always on the lookout for innovators and awesome people. Reach out if you are looking for a new opportunity."
      />

      <div className="wrapper">
        <section className="openings">
          <header className="openings__header">
            <motion.h1
              className="t-huge t-sans-alt"
              animate={{ opacity: [0, 1], y: [60, 0] }}
              transition={{ ease: 'circOut', duration: 1 }}
            >
              A fresh start
            </motion.h1>
            <motion.p
              className="t-beta t-sans-alt"
              animate={{ opacity: [0, 1], y: [60, 0] }}
              transition={{ ease: 'circOut', duration: 1, delay: 0.4 }}
            >
              Join the team that loves what it does and sweats about the
              details.
            </motion.p>
          </header>
          <section className="openings__layout">
            <ul className="openings__list">
              {/* <Position
                route={AppRoute.SeniorDesigner}
                title="Senior/Mid Interface Designer"
                description="We are looking for a skilled creative who is in love with design and digital technologies and has a good understanding of UX and UI principles. The candidate should be able to solve design problems with creative and visually appealing solutions. The Interface designer’s job is to understand the project, create wireframes, map out user journeys, and design interfaces people love to use."
              />
               <Position
                route={AppRoute.InterfaceDesigner}
                title="Interface Designer"
                description="Design products that solve problems and delight users. A designer is a skilled creative individual in love with design and digital technologies with a good understanding of UX and UI principles. The candidate should be able to solve design problems with creative and visually appealing solutions. A designer’s job is to understand the project, create wireframes, map out user journeys and design interfaces people love to use."
              /> 

              <Position
                route={AppRoute.InterfaceDeveloper}
                title="Interface Developer"
                description="Build memorable and functional user experiences. We expect our candidates to have strong JavaScript and browser API knowledge, and good understanding of React/Redux architecture and JavaScript build systems such as Webpack. TypeScript and React-Native experience is a plus."
              />

              <Position
                route={AppRoute.ReactNativeDeveloper}
                title="React Native Developer"
                description="Build user-friendly and highly performant solutions. We are looking for motivated and skilled React Native developers to have an understanding and knowledge of working with hybrid solutions (Android & iOS) in React Native & TypeScript. Find out what we can offer you and how to apply."
              />

              <Position
                route={AppRoute.AngularDeveloper}
                title="Angular Developer"
                description="Develop user-friendly and highly performant solutions. We are looking for an Angular developer, a skilled individual able to understand and implement solutions within technologies such as JavaScript, TypeScript, Angular, and NodeJS. Find out what we can offer you and how to apply."
              />

              <Position
                route={AppRoute.BackendDeveloperPHP}
                title="Backend Developer (PHP/Craft CMS)"
                description="Create quality content websites using PHP and CraftCMS! We are looking for a PHP backend developer, a skilled individual that thrives on creating quality services that can run websites with a high volume of visitors and serve a multitude of countries."
              />

              <Position
                route={AppRoute.BackendDeveloperNodeJS}
                title="Backend Developer (NodeJS)"
                description="Create a quality backbone for our apps and services! We are looking for a NodeJs backend developer, a skilled individual that thrives on creating quality services that power the applications we distribute to our clients. Find out what we can offer you and how to apply."
              />

              <Position
                route={AppRoute.ProjectManager}
                title="Project Manager"
                description="Join us in building and delivering great digital products. We are looking for a Project Manager who will plan and designate project resources, prepare budgets, and monitor progress while being a bond between our team and clients. Here’s what we offer and how to apply."
              />

              <Position
                route={AppRoute.QAEngineer}
                title="QA Engineer"
                description="Help our team deliver great quality software. We expect our QA engineers to have a broad technical knowledge, and the ability to research and address issues before they are visible to the end-user. A good QA Engineer will be an asset that provides insight into how users will use our solutions and work with the rest of the team to find an optimal approach on how to achieve that."
              /> */}

              <Position
                route={AppRoute.OpenApplication}
                title="Open Application"
                description="At this time, we don't have any open positions available. However, we are always on the lookout for talented individuals that are best in what they do."
              />
            </ul>
          </section>

          <section className="openings__starterkit" ref={starterKitRef}>
            <header className="openings__starterkit__header">
              <motion.h1
                className="t-sans-alt t-beta"
                animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 60 }}
                transition={{ ease: 'circOut', duration: 0.6 }}
              >
                Ready to launch your career?
              </motion.h1>
            </header>
            <div className="openings__starterkit__content">
              <motion.p
                className="t-sans-alt t-delta"
                animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
              >
                Have a look at our Starter kit and take the first step towards
                your new career.
              </motion.p>
              <motion.div
                animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
              >
                <Link
                  to={AppRoute.StarterKit}
                  className="actioncircle actioncircle--primary"
                >
                  Starter kit
                </Link>
              </motion.div>
            </div>
          </section>
        </section>
      </div>
    </React.Fragment>
  );
};
