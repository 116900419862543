import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { Publications } from 'const';

import { ArticleItem } from './ArticleItem';

export interface GhostResponse {
  posts: GhostPost[];
  meta: {
    page: number;
    limit: number;
    pages: number;
    total: number;
    next: number | null;
    prev: number | null;
  };
}

export interface GhostAuthor {
  id: string;
  name: string;
  profile_image: string;
  url: string;
}

export interface GhostPost {
  id: string;
  title: string;
  slug: string;
  authors: GhostAuthor[];
  description: string;
  image: string;
  link: string;
  url: string;
}

export const Articles: React.FC = () => {
  const [articles, setPublications] = useState<GhostPost[]>();
  useEffect(() => {
    fetch(Publications.GhostUrl)
      .then(res => res.json())
      .then((json: GhostResponse) => setPublications(json.posts.slice(0, 4)))
      .catch(err => console.log(err));
  }, []);

  const [prototypedRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  return (
    <section ref={prototypedRef} className="prototyped">
      <div className="wrapper">
        <div className="prototyped__layout">
          <header className="prototyped__heading">
            <motion.h1
              className="t-sans-alt t-gamma s-bottom--lrg"
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 60 }}
              transition={{ ease: 'circOut', duration: 0.6 }}
            >
              Prototyped&thinsp;&mdash;&thinsp;Thoughts on
              design&thinsp;&amp;&thinsp;tech
            </motion.h1>
          </header>
          <div className="prototyped__article__list">
            {articles?.map(post => (
              <ArticleItem key={post.id} {...post} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
