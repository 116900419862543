import React from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { AppRoute } from 'const';
import { Jumpstart } from 'components/home/fragments/Jumpstart';

import { TeamMember } from './TeamMember';

export const Team: React.FC = () => {
  const [teamRef, teamRefView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const [teamListRef, teamListRefView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  return (
    <section className="team" ref={teamRef}>
      <div className="wrapper">
        <header className="team__heading">
          <div className="team__heading__content">
            <div className="team__heading__title">
              <motion.h1
                className="t-alpha t-sans-alt"
                animate={{
                  opacity: teamRefView ? 1 : 0,
                  y: teamRefView ? 0 : 80,
                }}
                transition={{ ease: 'circOut', duration: 0.6 }}
              >
                A tight-knit team of tech, design and business️ people.
              </motion.h1>
            </div>
          </div>
          <div className="team__heading__action">
            <Link
              to={AppRoute.Openings}
              className="actioncircle actioncircle--negative"
            >
              View openings
            </Link>
          </div>
        </header>
        <motion.ul
          className="team__list"
          ref={teamListRef}
          animate={{
            opacity: teamListRefView ? 1 : 0,
            y: teamListRefView ? 0 : 100,
          }}
          transition={{ ease: 'circOut', duration: 1 }}
        >
          <TeamMember name="Dario Šoć" position="Developer" />

          <TeamMember name="Dino Stančić" position="Developer" />

          <TeamMember name="Jure Eljuga" position="Developer" />

          <TeamMember name="Josip Papež" position="Developer" />

          <TeamMember name="Josip Ravas" position="Developer" />

          <TeamMember name="Mateo Debeljak" position="Developer" />

          <TeamMember name="Matej Balat" position="Developer" />

          <TeamMember name="Matej Jakšić" position="Developer" />

          <TeamMember name="Matej Mijić" position="Developer" />

          <TeamMember name="Marko Boras" position="Developer" />

          <TeamMember name="Marko Mrkonjić" position="Developer" />

          <TeamMember name="Marko Stjepanek" position="Developer" />

          <TeamMember name="Stjepan Džojić" position="Developer" />

          <TeamMember name="Stjepan Posavec" position="Developer" />

          <TeamMember name="Zvonimir Stipanović" position="Developer" />

          <TeamMember name="Bojan Bušljeta" position="Backend Lead" />

          <TeamMember name="Luka Buljan" position="Mobile Lead" />

          <TeamMember name="Slobodan Džakić" position="System Architect" />

          <TeamMember name="Sebastijan Dumančić" position="Web Lead" />

          <TeamMember name="Bruno Predrijevac" position="Designer" />

          <TeamMember name="Darko Bogović" position="Designer" />

          <TeamMember name="Marko Kolić" position="Designer" />

          <TeamMember name="Ivo Anić" position="Design Lead" />

          <TeamMember name="Matej Dušić" position="Quality Assurance" />

          <TeamMember name="Sunčica Kojić" position="Quality Assurance" />

          <TeamMember name="Filip Bubalo" position="Marketing Manager" />

          <TeamMember name="Valentina Bermanec" position="Project Manager" />

          <TeamMember name="Stjepan Bumba" position="Project Manager" />

          <TeamMember name="Matej Lazić Švogor" position="Project Manager" />

          <TeamMember name="Željko Prša" position="Product Manager" />

          <TeamMember name="Robert Sekulić" position="COO" />

          <TeamMember name="Andrej Mlinarević" position="Founder / CEO" />

          <TeamMember name="Vlatko Vlahek" position="Founder / CTO" />
        </motion.ul>
      </div>

      <Jumpstart />
    </section>
  );
};
