import React, { useEffect, useState } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { Link } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { AppRoute } from 'const';

export const CookiePolicy: React.FC = () => {
  const gtmId = process.env.REACT_APP_GMT_ID || '';
  const [useTracking, setUseTracking] = useState(
    Cookies.get('CookieConsent') === 'true',
  );
  const gaCookieNames = Object.keys(Cookies.getJSON()).filter(key =>
    key.startsWith('_g'),
  );

  useEffect(() => {
    if (!useTracking) {
      /**
       * Removes Google Analytics cookies
       */
      gaCookieNames.forEach(cookieName => {
        Cookies.remove(cookieName);
      });
      return;
    }

    if (gtmId && useTracking) {
      TagManager.initialize({
        gtmId,
      });
    }
  }, [useTracking, gtmId, gaCookieNames]);

  const onAccept = () => setUseTracking(true);
  const onDecline = () => setUseTracking(false);

  return (
    <CookieConsent
      onAccept={onAccept}
      onDecline={onDecline}
      location="none"
      buttonText="I agree"
      containerClasses="cookie"
      contentClasses="cookie__content"
      buttonClasses="btn btn--med btn--dark"
      declineButtonClasses="btn btn--med btn--secondary"
      enableDeclineButton={true}
      disableStyles={true}
      expires={150}
    >
      <p className="t-zeta t-color-light">
        A quick note on your privacy. Our website only uses basic cookies to
        help us get more info about our visitors in general. How we use that
        data can be found in our{' '}
        <Link to={AppRoute.PrivacyPolicy}>Privacy Policy.</Link> By accepting we
        will only enable a Google Analytics cookie to be set. That's it.
      </p>
    </CookieConsent>
  );
};
