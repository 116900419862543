import React from 'react';

import { SEO } from 'components/shared';
import { useRevealAnimation } from 'hooks';

export const PrivacyPolicyPage: React.FC = () => {
  useRevealAnimation('light');

  return (
    <React.Fragment>
      <SEO
        seoTitle="Privacy Policy — PROTOTYP"
        seoDescription="Purpose of Privacy Policy is to explain how PROTOTYP d.o.o. as Data Controller uses the collected personal data."
      />

      <div className="wrapper">
        <section className="page">
          <div className="page__layout">
            <div className="page__heading">
              <h1 className="t-huge t-sans-alt">We care about your privacy</h1>
            </div>

            <div className="page__intro">
              <p className="t-delta t-sans-alt">
                Purpose of Privacy Policy is to explain how PROTOTYP d.o.o. as
                Data Controller uses the collected personal data. Also, it
                explains what is the purpose of collecting data and how can you
                contact us to get your data withdrawn from our data storage.
              </p>
            </div>

            <div className="page__content">
              <div className="page__section">
                <div className="page__section__heading">
                  <h2 className="t-gamma t-sans-alt">
                    What data do we collect?
                  </h2>
                </div>
                <div className="page__section__content">
                  <p className="t-epsilon s-bottom--xlrg">
                    PROTOTYP d.o.o. collects the following data:
                  </p>
                  <ul className="l-bordered">
                    <li>
                      Personal identification information (Name, email address,
                      phone number)
                    </li>
                    <li>
                      Business subject identification information (Name, email
                      address, phone number, VAT number, testimonials)
                    </li>
                  </ul>
                </div>
              </div>

              <div className="page__section">
                <div className="page__section__heading">
                  <h2 className="t-gamma t-sans-alt">
                    How do we collect your data?
                  </h2>
                </div>
                <div className="page__section__content">
                  <p className="t-epsilon s-bottom--xlrg">
                    You directly provide us with most of the data we collect. We
                    collect data and process it when you:
                  </p>
                  <ul className="l-bordered">
                    <li>Make a business inquiry.</li>
                    <li>
                      Make a job application for an open position or make an
                      open job application.
                    </li>
                    <li>Use or view our website via your browser’s cookies.</li>
                  </ul>
                  <p className="t-epsilon s-bottom--xlrg">
                    We may also receive your data indirectly from the following
                    sources:
                  </p>
                  <ul className="l-bordered">
                    <li>
                      Our partners that would like to recommend you to us
                      (business inquires and potential employees).
                    </li>
                  </ul>
                </div>
              </div>

              <div className="page__section">
                <div className="page__section__heading">
                  <h2 className="t-gamma t-sans-alt">
                    How will we use your data?
                  </h2>
                </div>
                <div className="page__section__content">
                  <p className="t-epsilon s-bottom--xlrg">
                    PROTOTYP d.o.o. collects your data so that we can:
                  </p>
                  <ul className="l-bordered">
                    <li>Make offers for our services.</li>
                    <li>Provide you with our services.</li>
                    <li>Bill you for our services.</li>
                    <li>
                      Contact you for job application assessment and interview.
                    </li>
                    <li>Contact you for an open job position.</li>
                  </ul>
                  <p className="t-epsilon s-bottom--xlrg">
                    We may also receive your data indirectly from the following
                    sources:
                  </p>
                  <ul className="l-bordered">
                    <li>
                      Our partners that would like to recommend you to us
                      (business inquires and potential employees).
                    </li>
                  </ul>
                </div>
              </div>

              <div className="page__section">
                <div className="page__section__heading">
                  <h2 className="t-gamma t-sans-alt">
                    How do we store your data?
                  </h2>
                </div>
                <div className="page__section__content">
                  <p className="t-epsilon s-bottom--xlrg">
                    PROTOTYP d.o.o. securely stores your data at online servers
                    and cloud storage.
                  </p>
                  <p>
                    We will keep your personal information for the time of 2
                    years. Once this time has expired, we will delete all of the
                    acquired data.
                  </p>
                </div>
              </div>

              <div className="page__section">
                <div className="page__section__heading">
                  <h2 className="t-gamma t-sans-alt">
                    What are your data protection rights?
                  </h2>
                </div>
                <div className="page__section__content">
                  <p className="t-epsilon s-bottom--xlrg">
                    We would like to make sure you are fully aware of all of
                    your data protection rights. Every user is entitled to the
                    following:
                  </p>
                  <ul className="l-bordered">
                    <li>
                      We will keep your personal information for the time of 2
                      years. Once this time has expired, we will delete all of
                      the acquired data.
                    </li>
                    <li>
                      <strong>The right to access</strong> – You have the right
                      to request copies of your data.
                    </li>
                    <li>
                      <strong>The right to rectification</strong> – You have the
                      right to request that we correct any information you
                      believe is inaccurate. You also have the right to request
                      us to complete the information you believe is incomplete.
                    </li>
                    <li>
                      <strong>The right to erasure</strong> – You have the right
                      to request that we erase your data, under certain
                      conditions.
                    </li>
                    <li>
                      <strong>The right to restrict processing</strong> – You
                      have the right to request that we restrict the processing
                      of your data, under certain conditions.
                    </li>
                    <li>
                      <strong>The right to object to processing</strong> – You
                      have the right to object to processing your data, under
                      certain conditions.
                    </li>
                    <li>
                      If you make a request, we have one month to respond to
                      you. If you would like to exercise any of these rights,
                      please contact us at our email: hello@prototyp.digital
                    </li>
                  </ul>
                </div>
              </div>

              <div className="page__section">
                <div className="page__section__heading">
                  <h2 className="t-gamma t-sans-alt">Cookies</h2>
                </div>
                <div className="page__section__content">
                  <h3 className="t-epsilon s-bottom--xlrg">
                    What types of cookies do we use?
                  </h3>
                  <p className="t-zeta s-bottom--xlrg">
                    There are several different types of cookies, however, our
                    website uses third-party cookies via Google Analytics. All
                    the data collected with Google Analytics helps us to know
                    your preferences, geolocation information and behavioral
                    information so we can improve our services and user
                    experience.
                  </p>
                  <ul className="l-bordered s-bottom--xlrg">
                    <li>_ga - Visitor identification</li>
                    <li>_gat - Throttling request rate</li>
                    <li>_gid - User journey</li>
                  </ul>
                  <h3 className="t-epsilon s-bottom--xlrg">
                    How do we use cookies?
                  </h3>
                  <p className="t-zeta s-bottom--xlrg">
                    We use cookies to collect information that will help us
                    create better services, website and blog content and improve
                    user experience.
                  </p>
                  <h3 className="t-epsilon s-bottom--xlrg">
                    How to manage cookies?
                  </h3>
                  <p>
                    You can set your browser not to accept cookies, and remove
                    all the cookies from your browser.
                  </p>
                </div>
              </div>

              <div className="page__section">
                <div className="page__section__heading">
                  <h2 className="t-gamma t-sans-alt">
                    Changes to our privacy policy
                  </h2>
                </div>
                <div className="page__section__content">
                  <p className="t-epsilon s-bottom--xlrg">
                    PROTOTYP d.o.o. keeps its privacy policy under regular
                    review and places any updates on this web page. This privacy
                    policy was last updated on April 6th, 2020.
                  </p>
                  <p>
                    If you have any questions about privacy policy, the data we
                    hold on you, or you would like to exercise one of your data
                    protection rights, please do not hesitate to contact us.
                  </p>
                </div>
              </div>

              <div className="page__section">
                <div className="page__section__heading">
                  <h2 className="t-gamma t-sans-alt">How to contact us?</h2>
                </div>
                <div className="page__section__content">
                  <ul className="l-bordered">
                    <li>PROTOTYP d.o.o.</li>
                    <li>Kordunska 12</li>
                    <li>Osijek, Croatia</li>
                    <li>hello@prototyp.digital</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};
