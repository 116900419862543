import React from 'react';

import { Footer } from './Footer';
import { Header } from './Header';

/**
 *
 * Represents the application global layout
 */
export const AppLayout: React.FC = ({ children }) => (
  <React.Fragment>
    <div className="container">
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  </React.Fragment>
);
