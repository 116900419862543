import React from 'react';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { AppRoute } from 'const';

interface Props {
  title: string;
  description: string;
  route: AppRoute;
  inactive?: boolean;
}

export const Position: React.FC<Props> = ({
  title,
  description,
  route,
  inactive,
}) => {
  const [positionRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  return (
    <li className="openings__list__item" ref={positionRef}>
      <div className="openings__list__item__title t-sans-alt">
        <motion.h2
          className="t-gamma"
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
          transition={{ ease: 'circOut', duration: 0.6 }}
        >
          {inactive ? (
            title
          ) : (
            <Link to={route} className="anchor anchor--text">
              {title}
            </Link>
          )}
        </motion.h2>
      </div>
      <div className="openings__list__item__content">
        <motion.p
          className="t-epsilon t-sans-alt s-bottom--med"
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
        >
          {description}
        </motion.p>
      </div>
    </li>
  );
};
