import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import { AppRoute } from 'const';

import { Logo } from './Logo';
import { Navigation } from './Navigation';

const altHeaderPaths = [
  AppRoute.Homepage,
  AppRoute.Services,
  // AppRoute.InterfaceDesigner,
  AppRoute.InterfaceDeveloper,
  AppRoute.ReactNativeDeveloper,
  AppRoute.AngularDeveloper,
  AppRoute.QAEngineer,
  AppRoute.SeniorDesigner,
  AppRoute.BackendDeveloperNodeJS,
  AppRoute.ProjectManager,
  AppRoute.BackendDeveloperPHP,
];

export const Header: React.FC = () => {
  const headerRef = useRef(null);

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isAltHeader, setIsAltHeader] = useState(true);

  const { pathname } = useLocation();
  useEffect(() => {
    setIsAltHeader(altHeaderPaths.includes(pathname as AppRoute));
  }, [pathname]);

  let headerClassName = 'header';
  if (isNavOpen) headerClassName += ' is-visible';
  if (isAltHeader) headerClassName += ' is-alternate';

  return (
    <header ref={headerRef} className={headerClassName}>
      <div className="header__wrapper">
        <div className="header__branding">
          <Logo isNavOpen={isNavOpen} isAltHeader={isAltHeader} />
        </div>
        <div className="header__nav">
          <Navigation
            isNavOpen={isNavOpen}
            isAltHeader={isAltHeader}
            onClose={closeNavigation}
          />
        </div>
      </div>
      <button
        onClick={toggleNavigation}
        className={isNavOpen ? 'nav__open btn is-active' : 'nav__open btn'}
      />
    </header>
  );

  function toggleNavigation() {
    isNavOpen ? closeNavigation() : openNavigation();
  }

  function openNavigation() {
    setIsNavOpen(true);

    document.body.classList.add('is-locked');
    document.documentElement.classList.add('is-locked');
  }

  // Used to close navigation by clicking on close button but also called on route change which fixes nav not closing when clicking on current route item.
  function closeNavigation() {
    setIsNavOpen(false);

    document.body.classList.remove('is-locked');
    document.documentElement.classList.remove('is-locked');
  }
};
