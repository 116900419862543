import { motion } from 'framer-motion';
import React from 'react';
import InView from 'react-intersection-observer';

import { CaseStudyTemplate } from './CaseStudyTemplate';

export const TolarPage: React.FC = () => (
  <CaseStudyTemplate
    seoTitle="Tolar — PROTOTYP"
    seoDescription="A revolutionary blockchain technology explorer and desktop wallet."
    imagePath="https://prototyp.digital/share/case-studies-share/tolar-cover.jpg"
  >
    <div className="wrapper">
      <header className="project__header">
        <motion.div
          className="project__header__left"
          initial={{ opacity: 0, y: 60 }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
        >
          <h1 className="t-caption--sm t-sans-alt">Tolar</h1>
          <p className="t-alpha t-sans-alt">
            A revolutionary blockchain technology explorer and desktop wallet
          </p>
        </motion.div>
        <div className="project__header__right" />
      </header>
    </div>

    <InView triggerOnce={true} threshold={0.3}>
      {({ ref, inView }) => (
        <motion.picture
          className="project__visual project__visual--fullwidth"
          ref={ref}
          initial={{ opacity: 0, y: 60 }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : 60,
          }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.8 }}
        >
          <source
            srcSet={require('assets/images/case-studies/tolar/tolar-cover.webp')}
            type="image/webp"
          />
          <source
            srcSet={require('assets/images/case-studies/tolar/tolar-cover.jpg')}
            type="image/jpeg"
          />
          <img
            loading="lazy"
            src={require('assets/images/case-studies/tolar/tolar-cover.jpg')}
            alt="Investors Club"
          />
        </motion.picture>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.3}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <section className="project__intro">
            <motion.div
              className="project__intro__heading"
              ref={ref}
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
            >
              <h2 className="t-beta t-sans-alt">
                A custom-created web solution with blockchain technology browser
                and desktop wallet which keeps the full copy of cryptocurrency
                in real-time.
              </h2>
            </motion.div>
            <motion.div
              className="project__intro__content"
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
            >
              <p className="t-delta t-sans-alt">
                For Tolar we built a solution that makes it easier for users to
                trade cryptocurrencies and store it in a personal desktop wallet
                - Masternode.
              </p>
            </motion.div>
          </section>
        </div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.2}>
      {({ ref, inView }) => (
        <motion.picture
          className="project__visual project__visual--fullwidth"
          ref={ref}
          initial={{ opacity: 0, y: 60 }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : 60,
          }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
        >
          <source
            srcSet={require('assets/images/case-studies/tolar/tolar-screen-1.webp')}
            type="image/webp"
          />
          <source
            srcSet={require('assets/images/case-studies/tolar/tolar-screen-1.jpg')}
            type="image/jpeg"
          />
          <img
            loading="lazy"
            src={require('assets/images/case-studies/tolar/tolar-screen-1.jpg')}
            alt="Investors Club"
          />
        </motion.picture>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.5}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <section className="project__section">
            <motion.div
              className="project__section__heading"
              ref={ref}
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
            >
              <h1 className="t-beta t-sans-alt">One platform for all</h1>
            </motion.div>
            <div className="project__section__layout1">
              <motion.div
                className="project__section__layout1__left"
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
              >
                <p className="t-gamma t-sans-alt">
                  Our idea was to design an interactive and effective web
                  solution that will be user-friendly, as well as creating a
                  desktop wallet suitable for the three major OSs.
                </p>
              </motion.div>
              <motion.div
                className="project__section__layout1__right"
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.8 }}
              >
                <p className="t-delta t-sans-alt">
                  The solutions represent the brand and its values while also
                  bringing much needed digital currency functionalities to
                  users.
                </p>
              </motion.div>
            </div>
          </section>
        </div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.2}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <motion.picture
            className="project__visual"
            ref={ref}
            initial={{ opacity: 0, y: 60 }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : 60,
            }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
          >
            <source
              srcSet={require('assets/images/case-studies/tolar/tolar-screen-2.webp')}
              type="image/webp"
            />
            <source
              srcSet={require('assets/images/case-studies/tolar/tolar-screen-2.jpg')}
              type="image/jpeg"
            />
            <img
              loading="lazy"
              src={require('assets/images/case-studies/tolar/tolar-screen-2.jpg')}
              alt="Investors Club"
            />
          </motion.picture>
        </div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={1}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <section className="project__section">
            <motion.div
              className="project__section__heading"
              ref={ref}
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
            >
              <h1 className="t-beta t-sans-alt">
                A wallet for all systems and real-time tracking
              </h1>
            </motion.div>
            <div className="project__section__layout1">
              <motion.div
                className="project__section__layout1__left"
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
              >
                <p className="t-gamma t-sans-alt">
                  Using React as the primary technology, we made an interactive
                  and user-friendly web solution which has a blockchain browser
                  as its biggest feature. As well as a blockchain desktop wallet
                  available for MacOS, Linux and Windows operating systems,
                  giving users easier access to their cryptocurrency in
                  real-time with ensured privacy of transactions.
                </p>
              </motion.div>
              <div className="project__section__layout1__right" />
            </div>
          </section>
        </div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.3}>
      {({ ref, inView }) => (
        <motion.div
          className="wrapper"
          ref={ref}
          initial={{ opacity: 0, y: 60 }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : 60,
          }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
        >
          <picture className="project__visual">
            <source
              srcSet={require('assets/images/case-studies/tolar/tolar-screen-3.webp')}
              type="image/webp"
            />
            <source
              srcSet={require('assets/images/case-studies/tolar/tolar-screen-3.jpg')}
              type="image/jpeg"
            />
            <img
              loading="lazy"
              src={require('assets/images/case-studies/tolar/tolar-screen-3.jpg')}
              alt="Investors Club"
            />
          </picture>
        </motion.div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.3}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <section className="project__meta">
            <div className="project__meta__layout">
              <motion.div
                ref={ref}
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
              >
                <h2 className="t-delta t-sans-alt">Platforms</h2>
                <ul className="t-epsilon">
                  <li>Web</li>
                  <li>Desktop</li>
                </ul>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
              >
                <h2 className="t-delta t-sans-alt">Deliverables</h2>
                <ul className="t-epsilon">
                  <li>Product design (UI / UX)</li>
                  <li>Product Development</li>
                  <li>Blockchain explorer and desktop wallet</li>
                </ul>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
              >
                <h2 className="t-delta t-sans-alt">Technology</h2>
                <ul className="t-epsilon">
                  <li>React</li>
                  <li>TypeScript</li>
                  <li>Electron</li>
                </ul>
              </motion.div>
            </div>
          </section>
        </div>
      )}
    </InView>
  </CaseStudyTemplate>
);
