import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { SEO } from 'components/shared';
import { useRevealAnimation } from 'hooks';
import { CTA } from 'components/home/fragments/CTA';

import { OSC, Team } from './fragments';

export const CompanyPage: React.FC = () => {
  useRevealAnimation('light');

  const [companyInfo, companyInfoinView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const [companyFeatures, companyFeaturesinView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const [companyTrusted, companyTrustedinView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const [ourClients, ourClientsinView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  return (
    <React.Fragment>
      <SEO
        seoTitle="Meet the team — PROTOTYP"
        seoDescription="Our non-automated team, still not replaced by robots, can take you from an idea to the real world product."
      />
      <section className="company">
        <div className="wrapper">
          <header className="company__header">
            <motion.h1
              className="t-huge t-sans-alt"
              animate={{ opacity: [0, 1], y: [80, 0] }}
              transition={{ ease: 'circOut', duration: 1 }}
            >
              Partner up with a team that builds it like they own it
            </motion.h1>
            <div className="company__header__keypoints">
              <motion.div
                animate={{ opacity: [0, 1], y: [60, 0] }}
                transition={{ ease: 'circOut', duration: 1, delay: 0.6 }}
              >
                <span className="t-beta t-sans-alt">5+</span>
                <p className="t-epsilon">
                  years of steady and measurable growth in the business.
                </p>
              </motion.div>
              <motion.div
                animate={{ opacity: [0, 1], y: [60, 0] }}
                transition={{ ease: 'circOut', duration: 1, delay: 0.8 }}
              >
                <span className="t-beta t-sans-alt">30+</span>
                <p className="t-epsilon">
                  designers, developers and tech specialists.
                </p>
              </motion.div>
            </div>
          </header>
        </div>
        <div className="wrapper">
          <section className="company__intro" ref={companyInfo}>
            <header className="company__intro__header">
              <motion.h2
                className="t-huge t-sans-alt"
                animate={{
                  opacity: companyInfoinView ? 1 : 0,
                  y: companyInfoinView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6 }}
              >
                Specialized digital product company
              </motion.h2>
              <motion.p
                className="t-delta t-sans-alt t-color-light"
                animate={{
                  opacity: companyInfoinView ? 1 : 0,
                  y: companyInfoinView ? 0 : 40,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
              >
                Great products come from great teams. Take your product to the
                next level and find a market-fit, fast and on budget.
              </motion.p>
            </header>
            <motion.div
              className="company__intro__visual"
              animate={{
                opacity: companyInfoinView ? 1 : 0,
                y: companyInfoinView ? 0 : 80,
              }}
              transition={{ ease: 'circOut', duration: 1, delay: 0.6 }}
            >
              <img
                loading="lazy"
                src={require('assets/images/visuals/partner-visual-app.jpg')}
                alt="Specialized digital product company"
              />
            </motion.div>
          </section>
        </div>
        <div className="wrapper">
          <section className="company__features" ref={companyFeatures}>
            <ul>
              <motion.li
                animate={{
                  opacity: companyFeaturesinView ? 1 : 0,
                  y: companyFeaturesinView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6 }}
              >
                <span className="t-delta t-sans-alt t-color-ultralight">
                  01
                </span>
                <h2 className="t-beta t-sans-alt">Products that disrupt</h2>
                <p className="t-delta t-sans-alt t-color-light">
                  Envision and build a disruptive product that hits that sweet
                  spot and connects users and businesses.
                </p>
              </motion.li>
              <motion.li
                animate={{
                  opacity: companyFeaturesinView ? 1 : 0,
                  y: companyFeaturesinView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
              >
                <span className="t-delta t-sans-alt t-color-ultralight">
                  02
                </span>
                <h2 className="t-beta t-sans-alt">Ahead of the curve</h2>
                <p className="t-delta t-sans-alt t-color-light">
                  Build your product with cutting edge tech. We practice
                  industry proven standards and practices to build products that
                  kick-ass.
                </p>
              </motion.li>
              <motion.li
                animate={{
                  opacity: companyFeaturesinView ? 1 : 0,
                  y: companyFeaturesinView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
              >
                <span className="t-delta t-sans-alt t-color-ultralight">
                  03
                </span>
                <h2 className="t-beta t-sans-alt">
                  Your business is our business
                </h2>
                <p className="t-delta t-sans-alt t-color-light">
                  Our goal is to build and support your product like it’s our
                  own. We ideate, test, discover and tweak as your product
                  grows.
                </p>
              </motion.li>
            </ul>
          </section>
        </div>
        <div className="wrapper">
          <section className="company__trusted" ref={companyTrusted}>
            <motion.h2
              className="t-delta t-sans-alt t-color-ultralight"
              animate={{
                opacity: companyTrustedinView ? 1 : 0,
                y: companyTrustedinView ? 0 : 40,
              }}
              transition={{ ease: 'circOut', duration: 0.6 }}
            >
              Trusted by startups&thinsp;&amp;&thinsp;enterprises.
            </motion.h2>
            <motion.p
              className="t-alpha t-sans-alt"
              animate={{
                opacity: companyTrustedinView ? 1 : 0,
                y: companyTrustedinView ? 0 : 80,
              }}
              transition={{ ease: 'circOut', duration: 0.8, delay: 0.4 }}
            >
              We build successful products for fintech, healthcare, sports,
              media, technology, networking, property, retail and SaaS
              industries across the globe.
            </motion.p>
            <motion.div
              className="w-negativemargo"
              animate={{
                opacity: companyTrustedinView ? 1 : 0,
                y: companyTrustedinView ? 0 : 100,
              }}
              transition={{ ease: 'circOut', duration: 1, delay: 0.6 }}
            >
              <img
                loading="lazy"
                src={require('assets/images/visuals/partner-visual-products.jpg')}
                alt="Trusted by startups & enterprises"
              />
            </motion.div>
          </section>
        </div>
        <div className="wrapper">
          <section className="company__clients" ref={ourClients}>
            <header className="company__clients__header">
              <motion.h2
                className="t-beta t-sans-alt"
                animate={{
                  opacity: ourClientsinView ? 1 : 0,
                  y: ourClientsinView ? 0 : 40,
                }}
                transition={{ ease: 'circOut', duration: 0.6 }}
              >
                Our clients
              </motion.h2>
            </header>
            <motion.div
              className="company__clients__list"
              animate={{
                opacity: ourClientsinView ? 1 : 0,
                y: ourClientsinView ? 0 : 80,
              }}
              transition={{ ease: 'circOut', duration: 0.8, delay: 0.4 }}
            >
              <ul className="t-epsilon t-sans-alt">
                <li>Alpha Investors</li>
                <li>Bornfight</li>
                <li>Barrage</li>
                <li>Dovetail</li>
                <li>Dobro</li>
                <li>Highlander</li>
                <li>Inchoo</li>
                <li>Miyagi</li>
                <li>Orbico Beauty</li>
                <li>ORQA</li>
                <li>Pevex</li>
                <li>Resonate</li>
                <li>RAK Wireless</li>
                <li>Together</li>
                <li>QuickDBD</li>
                <li>Sartura</li>
                <li>Sciensus</li>
                <li>Sentinel</li>
                <li>Styria</li>
                <li>TBTL</li>
                <li>Tolar</li>
                <li>WellAir</li>
                <li>Wienerberger</li>
                <li>Žito…</li>
              </ul>
            </motion.div>
          </section>
        </div>
        <Team />
        <OSC />
        <CTA />
      </section>
    </React.Fragment>
  );
};
