import anime, { AnimeAnimParams } from 'animejs';
import { useCallback, useEffect, useRef } from 'react';
import ScrollMagic, { ControllerOptions, SceneOptions } from 'scrollmagic';

interface TimelineScene {
  ref: React.MutableRefObject<any> | null;
  scene?: ScrollMagic.Scene;
  animations: TimelineAnimation[];
}

interface TimelineAnimation {
  ref: React.MutableRefObject<any>;
  animation: AnimeAnimParams;
  timelineOffset?: number | string;
}

interface UseAnimation {
  add: (
    ref: React.MutableRefObject<any>,
    animation: AnimeAnimParams,
    timelineOffset?: number | string,
  ) => Pick<UseAnimation, 'add' | 'animate' | 'addScene'>;
  withScrollMagic: (
    options?: ControllerOptions,
  ) => Pick<UseAnimation, 'addScene'>;
  addScene: (
    ref: React.MutableRefObject<any>,
    options?: SceneOptions,
  ) => Pick<UseAnimation, 'add'>;
  animate: () => void;
}

export const useAnimation = (): Pick<
  UseAnimation,
  'add' | 'withScrollMagic'
> => {
  const playAnimation = useRef(false);
  const scrollMagicController = useRef<ScrollMagic.Controller>();
  const timelineScenes = useRef<TimelineScene[]>([
    { ref: null, animations: [] },
  ]);

  const animate = useCallback(() => {
    playAnimation.current = true;
  }, []);

  useEffect(() => {
    if (!playAnimation) {
      return;
    }

    timelineScenes.current.forEach(({ ref, scene, animations }) => {
      if (scrollMagicController.current && scene) {
        scene
          .triggerElement(ref?.current)
          .on('enter', () => triggerAnimation(animations))
          .addTo(scrollMagicController.current);

        return;
      }

      triggerAnimation(animations);
    });

    function triggerAnimation(animations: TimelineAnimation[]) {
      switch (animations.length) {
        case 0:
          return;
        case 1:
          animateElement(animations);
          break;
        default:
          animateTimeline(animations);
          break;
      }
    }

    function animateElement(animations: TimelineAnimation[]) {
      const [{ ref, animation }] = animations;
      return anime({ targets: ref.current, ...animation });
    }

    function animateTimeline(animations: TimelineAnimation[]) {
      const timeline = anime.timeline();
      animations.forEach(({ ref, animation, timelineOffset }) =>
        timeline.add({ targets: ref.current, ...animation }, timelineOffset),
      );

      return timeline;
    }

    return () => {
      scrollMagicController.current?.destroy(true);
    };
  }, [playAnimation]);

  function withScrollMagic(
    this: Pick<UseAnimation, 'addScene'>,
    options?: ControllerOptions,
  ) {
    scrollMagicController.current = new ScrollMagic.Controller(options);
    return { addScene };
  }

  function addScene(
    this: Pick<UseAnimation, 'add'>,
    ref: React.MutableRefObject<any>,
    sceneOptions?: SceneOptions,
  ) {
    const [firstScene] = timelineScenes.current;
    if (!firstScene.scene) {
      firstScene.ref = ref;
      firstScene.scene = new ScrollMagic.Scene(sceneOptions);
    } else {
      timelineScenes.current.push({
        ref,
        scene: new ScrollMagic.Scene(sceneOptions),
        animations: [],
      });
    }

    return { add };
  }

  function add(
    this: Pick<UseAnimation, 'add' | 'animate' | 'addScene'>,
    ref: React.MutableRefObject<any>,
    animation: AnimeAnimParams,
    timelineOffset?: number | string,
  ) {
    const currentScene = timelineScenes.current?.slice(-1).pop();
    currentScene?.animations.push({
      ref,
      animation,
      timelineOffset,
    });

    return { add, animate, addScene };
  }

  return {
    add,
    withScrollMagic,
  };
};
