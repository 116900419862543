import { motion } from 'framer-motion';
import React from 'react';
import InView from 'react-intersection-observer';

interface Props {
  benefits?: { title: string; description: string }[];
}

export const CustomBenefits: React.FC<Props> = ({ benefits }) => {
  if (!benefits?.length) {
    return null;
  }

  return (
    <ul className="opening__list">
      {benefits?.map(({ title, description }) => (
        <InView key={title} triggerOnce={true} threshold={0.5}>
          {({ ref, inView }) => (
            <motion.li
              ref={ref}
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
            >
              <div className="opening__list__wrapper">
                <span className="opening__list_icon">
                  <img src={require('assets/icons/pulse.svg')} alt="" />
                </span>
                <h3 className="t-delta t-sans-alt">{title}</h3>
              </div>
              <p className="opening__list__description--primary t-epsilon t-color-light">
                {description}
              </p>
            </motion.li>
          )}
        </InView>
      ))}
    </ul>
  );
};
