import React from 'react';
import { render } from 'react-dom';

import { serviceWorker } from 'workers';
import 'normalize.css';
import 'css/app.css';

import { App } from './App';

/**
 * Import and render the Website application
 */
render(<App />, document.getElementById('root'));

/**
 * Registers the service worker
 */
serviceWorker.unregister();
