import { motion } from 'framer-motion';
import React from 'react';
import InView from 'react-intersection-observer';

import { CaseStudyTemplate } from './CaseStudyTemplate';

export const PWAPage: React.FC = () => (
  <CaseStudyTemplate
    seoTitle="PWA audio player — PROTOTYP"
    seoDescription="Keep listening to music & podcasts from the browser when on the move."
    imagePath="https://prototyp.digital/share/case-studies-share/pwa-cover.jpg"
  >
    <div className="wrapper">
      <header className="project__header">
        <motion.div
          className="project__header__left"
          initial={{ opacity: 0, y: 60 }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
        >
          <h1 className="t-caption--sm t-sans-alt">PWA audio player</h1>
          <p className="t-alpha t-sans-alt">
            Keep listening to music & podcasts from the browser when on the move
          </p>
        </motion.div>
        <div className="project__header__right" />
      </header>
    </div>

    <InView triggerOnce={true} threshold={0.3}>
      {({ ref, inView }) => (
        <motion.picture
          className="project__visual project__visual--fullwidth"
          ref={ref}
          initial={{ opacity: 0, y: 60 }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : 60,
          }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.8 }}
        >
          <source
            srcSet={require('assets/images/case-studies/pwa/pwa-cover.webp')}
            type="image/webp"
          />
          <source
            srcSet={require('assets/images/case-studies/pwa/pwa-cover.jpg')}
            type="image/jpeg"
          />
          <img
            loading="lazy"
            src={require('assets/images/case-studies/pwa/pwa-cover.jpg')}
            alt="Investors Club"
          />
        </motion.picture>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.3}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <section className="project__intro">
            <motion.div
              className="project__intro__heading"
              ref={ref}
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
            >
              <h2 className="t-beta t-sans-alt">
                Progressive Web App with an integrated audio player that allows
                users to listen to the audio in the background of an app.
              </h2>
            </motion.div>
            <motion.div
              className="project__intro__content"
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
            >
              <p className="t-delta t-sans-alt">
                The client needed an app that doesn't cut your audio when you’re
                not actively using it. We came up with a solution of PWA with
                integrated playback that solves this problem and provides users
                with a fully-functioning background player.
              </p>
            </motion.div>
          </section>
        </div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.2}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <motion.picture
            className="project__visual"
            ref={ref}
            initial={{ opacity: 0, y: 60 }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : 60,
            }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
          >
            <source
              srcSet={require('assets/images/case-studies/pwa/pwa-screen-1.webp')}
              type="image/webp"
            />
            <source
              srcSet={require('assets/images/case-studies/pwa/pwa-screen-1.jpg')}
              type="image/jpeg"
            />
            <img
              loading="lazy"
              src={require('assets/images/case-studies/pwa/pwa-screen-1.jpg')}
              alt="Investors Club"
            />
          </motion.picture>
        </div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.4}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <section className="project__section">
            <motion.div
              className="project__section__heading"
              ref={ref}
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
            >
              <h1 className="t-beta t-sans-alt">Make it sound good</h1>
            </motion.div>
            <motion.div
              className="project__section__layout1"
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
            >
              <div className="project__section__layout1__left">
                <p className="t-gamma t-sans-alt">
                  The chosen approach was to implement background media playback
                  in the Progressive Web App. It allowed us to easily span all
                  platforms under one application while also supporting the
                  biggest feature in the app — seamless background audio
                  playback.
                </p>
              </div>
              <div className="project__section__layout1__right" />
            </motion.div>
          </section>
        </div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.1}>
      {({ ref, inView }) => (
        <motion.div
          className="wrapper"
          ref={ref}
          initial={{ opacity: 0, y: 60 }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : 60,
          }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
        >
          <picture className="project__visual">
            <source
              srcSet={require('assets/images/case-studies/pwa/pwa-screen-2.webp')}
              type="image/webp"
            />
            <source
              srcSet={require('assets/images/case-studies/pwa/pwa-screen-2.jpg')}
              type="image/jpeg"
            />
            <img
              loading="lazy"
              src={require('assets/images/case-studies/pwa/pwa-screen-2.jpg')}
              alt="Investors Club"
            />
          </picture>
        </motion.div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={1}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <section className="project__section">
            <motion.div
              className="project__section__heading"
              ref={ref}
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
            >
              <h1 className="t-beta t-sans-alt">Clear as a bell</h1>
            </motion.div>
            <div className="project__section__layout1">
              <motion.div
                className="project__section__layout1__left"
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
              >
                <p className="t-delta t-sans-alt">
                  The product is a full-featured PWA you can add to your home
                  screen just like you would with a native app. Users listen to
                  their favorite podcasts or music in the background while their
                  phone is locked or using another app.
                </p>
              </motion.div>
              <motion.div
                className="project__section__layout1__right"
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.8 }}
              >
                <p className="t-delta t-sans-alt">
                  One obstacle to cross was Apple's lack of compatibility with
                  PWA audio playback. That's why we had to ship a native app
                  just for iOS. To find out more about the process of developing
                  the app, read a{' '}
                  <a
                    href="https://blog.prototyp.digital/what-we-learned-about-pwas-and-audio-playback/"
                    className="anchor anchor--text"
                  >
                    blog post
                  </a>{' '}
                  our Luka wrote.
                </p>
              </motion.div>
            </div>
          </section>
        </div>
      )}
    </InView>

    <InView triggerOnce={true} threshold={0.3}>
      {({ ref, inView }) => (
        <div className="wrapper">
          <section className="project__meta">
            <motion.div
              className="project__meta__layout"
              ref={ref}
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
            >
              <div>
                <h2 className="t-delta t-sans-alt">Platforms</h2>
                <ul className="t-epsilon">
                  <li>Web</li>
                </ul>
              </div>
              <motion.div
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
              >
                <h2 className="t-delta t-sans-alt">Deliverables</h2>
                <ul className="t-epsilon">
                  <li>Product design (UI / UX)</li>
                  <li>Product Development</li>
                </ul>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
              >
                <h2 className="t-delta t-sans-alt">Technology</h2>
                <ul className="t-epsilon">
                  <li>React</li>
                  <li>TypeScript</li>
                  <li>Firebase</li>
                </ul>
              </motion.div>
            </motion.div>
          </section>
        </div>
      )}
    </InView>
  </CaseStudyTemplate>
);
