import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';

import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { AppRoute } from 'const';
import { ReactComponent as LogoSvg } from 'assets/icons/logo.svg';
import { ReactComponent as PangeaSvg } from 'assets/icons/pangea.svg';

export const Footer: React.FC = () => {
  const linksRef = useRef<HTMLElement[]>([]);

  const registerRef = (ref: HTMLElement | null) => {
    if (!ref) return;
    linksRef.current.push(ref);
  };

  const [footerRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  return (
    <footer className="footer" ref={footerRef}>
      <div className="wrapper">
        <div className="footer__top">
          <motion.div
            className="footer__logo"
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
            transition={{ ease: 'circOut', duration: 0.6 }}
          >
            <LogoSvg />
          </motion.div>

          <motion.div
            className="footer__pangea"
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
          >
            <a
              href="https://pangea.ai/vendors/prototyp/about"
              rel="nofollow"
              title="Hire elite software development vendors at Pangea"
            >
              <PangeaSvg />
              <p className="t-zeta t-negative t-opaque-50">
                Verified as the top global 7%
              </p>
            </a>
          </motion.div>
        </div>

        <div className="footer__links">
          <motion.section
            ref={registerRef}
            className="footer__links__group footer__links__group--pages"
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
          >
            <ul>
              {/* <li className="footer__links__item">
                <NavLink className="footer__links__link" to={AppRoute.Services}>
                  Success stories
                </NavLink>
              </li> */}
              <li className="footer__links__item">
                <NavLink className="footer__links__link" to={AppRoute.Services}>
                  Build a product
                </NavLink>
              </li>
              <li className="footer__links__item">
                <NavLink className="footer__links__link" to={AppRoute.Company}>
                  Partner with us
                </NavLink>
              </li>
            </ul>
          </motion.section>

          <motion.section
            ref={registerRef}
            className="footer__links__group footer__links__group--pages"
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 0.7 }}
          >
            <ul>
              <li className="footer__links__item">
                <NavLink className="footer__links__link" to={AppRoute.Contact}>
                  Schedule a meeting
                </NavLink>
              </li>
              <li className="footer__links__item">
                <NavLink className="footer__links__link" to={AppRoute.Openings}>
                  Careers
                </NavLink>
              </li>
            </ul>
          </motion.section>

          <motion.section
            ref={registerRef}
            className="footer__links__group footer__links__group--publishing"
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 1.0 }}
          >
            <ul>
              <li className="footer__links__item">
                <a
                  className="footer__links__link"
                  href="https://blog.prototyp.digital/"
                >
                  Blog
                </a>
              </li>
              <li className="footer__links__item">
                <a
                  className="footer__links__link"
                  href="https://relay.prototyp.digital/"
                >
                  Relay
                </a>
              </li>
              <li className="footer__links__item">
                <a
                  className="footer__links__link"
                  href="https://medium.com/prototyped"
                >
                  Medium
                </a>
              </li>
              <li className="footer__links__item">
                <a
                  className="footer__links__link"
                  href="https://dev.to/prototyp"
                >
                  Dev.to
                </a>
              </li>
              <li className="footer__links__item">
                <a
                  className="footer__links__link"
                  href="https://dribbble.com/prototyp"
                >
                  Dribbble
                </a>
              </li>
            </ul>
          </motion.section>

          <motion.section
            ref={registerRef}
            className="footer__links__group footer__links__group--social"
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 1.3 }}
          >
            <ul>
              <li className="footer__links__item">
                <a
                  className="footer__links__link"
                  href="https://www.linkedin.com/company/prototyp-d-o-o-/"
                >
                  Linkedin
                </a>
              </li>
              <li className="footer__links__item">
                <a
                  className="footer__links__link"
                  href="https://twitter.com/_prototyp"
                >
                  Twitter
                </a>
              </li>
              <li className="footer__links__item">
                <a
                  className="footer__links__link"
                  href="https://clutch.co/profile/prototyp"
                >
                  Clutch
                </a>
              </li>
              <li className="footer__links__item">
                <a
                  className="footer__links__link"
                  href="https://instagram.com/prototypdigital"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </motion.section>

          <motion.section
            ref={registerRef}
            className="footer__links__group footer__links__group--company"
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 1.6 }}
          >
            <ul>
              <li className="footer__links__item">
                <a
                  className="footer__links__link"
                  href="mailto: hello@prototyp.digital"
                >
                  hello@prototyp.digital
                </a>
              </li>
              <li className="footer__links__item">&nbsp;</li>
            </ul>
            <address className="footer__text">
              PROTOTYP d.o.o.
              <br />
              Kordunska 12, Osijek, Croatia
            </address>
            <ul>
              <li className="footer__text">OIB: 69410380024</li>
              <li className="footer__text">VAT ID: HR69410380024</li>
              <li className="footer__text">
                <NavLink
                  className="footer__links__link"
                  to={AppRoute.PrivacyPolicy}
                >
                  Privacy Policy
                </NavLink>
              </li>
            </ul>
          </motion.section>
        </div>
      </div>
    </footer>
  );
};
