import React from 'react';
import { Link } from 'react-router-dom';

import { SEO } from 'components/shared';
import { useRevealAnimation } from 'hooks';
import { AppRoute } from 'const';

export const StarterKitPage: React.FC = () => {
  useRevealAnimation('light');

  return (
    <React.Fragment>
      <SEO
        seoTitle="Starter Kit — PROTOTYP"
        seoDescription="Not sure what is needed to qualify? Take a look at our Starter kit and see if you got what it takes to get started quickly."
      />
      <div className="wrapper">
        <section className="page">
          <div className="page__layout">
            <div className="page__heading">
              <h1 className="t-huge t-sans-alt">Kick off your career</h1>
            </div>

            <div className="page__intro">
              <p className="t-delta t-sans-alt">
                We're delighted that you decided to make us a part of your
                digital career. This starter kit helps you get on your feet
                quickly.
              </p>
            </div>

            <div className="page__content">
              <section className="page__section">
                <div className="page__section__heading">
                  <h2 className="t-gamma t-sans-alt">
                    Technology &amp; Design
                  </h2>
                </div>
                <div className="page__section__content">
                  <div className="formatted">
                    <p>
                      Our solutions are based on Typescript, React,
                      React-Native, Angular, .NET Core and NodeJS, although we
                      consider ourselves engineers, always open to new
                      challenges and fresh technologies.
                    </p>

                    <p>
                      We also work with BaaS and IaaS services such as Firebase,
                      Amazon AWS and Azure.
                    </p>

                    <p>
                      Our design team uses the latest tools of the trade for
                      their trickery such as Sketch, Figma and Adobe
                      Illustrator.
                    </p>
                  </div>
                </div>
              </section>

              <section className="page__section">
                <div className="page__section__heading">
                  <h2 className="t-gamma t-sans-alt">What do we offer?</h2>
                </div>
                <div className="page__section__content">
                  <div className="formatted">
                    <ul className="l-bordered">
                      <li>
                        Competitive salary dependent on skill and experience
                      </li>
                      <li>
                        Friendly atmosphere where people will help you learn
                      </li>
                      <li>Flexible working hours and days off</li>
                      <li>Interesting projects</li>
                      <li>Paid educations and conferences</li>
                      <li>
                        Growth planning and support to bring out the most of
                        your potential
                      </li>
                    </ul>
                  </div>
                </div>
              </section>

              <section className="page__section">
                <div className="page__section__heading">
                  <h2 className="t-gamma t-sans-alt">
                    What do we ask in return?
                  </h2>
                </div>
                <div className="page__section__content">
                  <div className="formatted">
                    <ul className="l-bordered">
                      <li>Responsibility towards work and our clients</li>
                      <li>Technical excellence</li>
                      <li>
                        Proactivity in suggesting new technologies and practices
                      </li>
                      <li>Sharing knowledge</li>
                      <li>Good communication skills</li>
                      <li>Fluency in English </li>
                      <li>Being a team player</li>
                      <li>
                        Willingness to adapt to new and upcoming technologies
                      </li>
                      <li>Focus on quality UX</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section className="page__section">
                <div className="page__section__heading">
                  <h2 className="t-gamma t-sans-alt">Positions</h2>
                </div>
                <div className="page__section__content">
                  <ul className="l-dashed">
                    <li>
                      <Link
                        to={AppRoute.SeniorDesigner}
                        className="anchor anchor--text"
                      >
                        Senior/Mid Interface Designer
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to={AppRoute.InterfaceDesigner}
                        className="anchor anchor--text"
                      >
                        Interface Designer
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        to={AppRoute.InterfaceDeveloper}
                        className="anchor anchor--text"
                      >
                        Interface Developer
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={AppRoute.ReactNativeDeveloper}
                        className="anchor anchor--text"
                      >
                        React Native Developer
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={AppRoute.AngularDeveloper}
                        className="anchor anchor--text"
                      >
                        Angular Developer
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={AppRoute.BackendDeveloperPHP}
                        className="anchor anchor--text"
                      >
                        Backend Developer (PHP/Craft CMS)
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={AppRoute.BackendDeveloperNodeJS}
                        className="anchor anchor--text"
                      >
                        Backend Developer (NodeJS)
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={AppRoute.ProjectManager}
                        className="anchor anchor--text"
                      >
                        Project Manager
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={AppRoute.QAEngineer}
                        className="anchor anchor--text"
                      >
                        QA Engineer
                      </Link>
                    </li>
                  </ul>
                </div>
              </section>

              <section className="page__section">
                <div className="page__section__heading">
                  <h2 className="t-gamma t-sans-alt">Learning materials</h2>
                </div>
                <div className="page__section__content">
                  <div className="formatted">
                    <p>
                      Our primary stack is React, Redux &amp; Typescript, so our
                      materials are focused on getting to know the basics of web
                      app development through this modern setup, which allows us
                      to create performant, high quality and dynamic interfaces
                      for our clients, which are easy to implement and test.
                    </p>

                    <p className="t-delta t-sans-alt">Basics</p>
                    <ul className="l-dashed">
                      <li>
                        <a
                          href="https://code.visualstudio.com/"
                          className="anchor anchor--text"
                        >
                          Visual Studio Code
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://nodejs.org/en/"
                          className="anchor anchor--text"
                        >
                          Node.js &amp; NPM
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://github.com/webpack/webpack"
                          className="anchor anchor--text"
                        >
                          Webpack
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://git-scm.com/"
                          className="anchor anchor--text"
                        >
                          GIT
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.typescriptlang.org/"
                          className="anchor anchor--text"
                        >
                          Typescript
                        </a>
                      </li>
                    </ul>

                    <p className="t-delta t-sans-alt">Git</p>
                    <ul className="l-dashed">
                      <li>
                        <a
                          href="https://bitbucket.org/"
                          className="anchor anchor--text"
                        >
                          Bitbucket
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://github.com"
                          className="anchor anchor--text"
                        >
                          GitHub
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://gitlab.com"
                          className="anchor anchor--text"
                        >
                          GitLab
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>

              <section className="page__section">
                <div className="page__section__heading">
                  <h2 className="t-gamma t-sans-alt">Starting point</h2>
                </div>
                <div className="page__section__content">
                  <div className="formatted">
                    <p>
                      If you are unsure where to start, we compiled a list of
                      links which will help you understand are requirements
                      thoroughly:
                    </p>

                    <ul className="l-dashed">
                      <li>
                        <a
                          href="https://developer.mozilla.org/en-US/docs/Web"
                          className="anchor anchor--text"
                        >
                          Mozilla Developer Network: HTTP, HTML, CSS, JS, APIs
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://getbem.com/"
                          className="anchor anchor--text"
                        >
                          BEM – Block Element Modifier
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://github.com/postcss/postcss"
                          className="anchor anchor--text"
                        >
                          PostCSS
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://css-tricks.com/snippets/css/complete-guide-grid/"
                          className="anchor anchor--text"
                        >
                          CSS Grids
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://webtypography.net/"
                          className="anchor anchor--text"
                        >
                          Basics of web typography
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.sitepoint.com/progressive-enhancement-graceful-degradation-basics/"
                          className="anchor anchor--text"
                        >
                          Graceful degradation and progressive enhancement
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://medium.com/@vicompany/vertical-rhythm-the-holy-grail-of-front-end-development-8f86eb152f18?ref=webdesignernews.com"
                          className="anchor anchor--text"
                        >
                          Vertical Rhythm
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://facebook.github.io/react/"
                          className="anchor anchor--text"
                        >
                          React by Facebook
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://redux.js.org/"
                          className="anchor anchor--text"
                        >
                          Redux
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.sitepoint.com/javascript-truthy-falsy/"
                          className="anchor anchor--text"
                        >
                          Javascript Truthy / Falsy
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.typescriptlang.org/docs/handbook/basic-types.html"
                          className="anchor anchor--text"
                        >
                          Typescript Handbook
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://github.com/typescript-cheatsheets/react-typescript-cheatsheet"
                          className="anchor anchor--text"
                        >
                          React cheetsheet for React
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://eslint.org/docs/developer-guide/code-conventions"
                          className="anchor anchor--text"
                        >
                          Code Conventions for JavaScript by ESLint
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>

              <section className="page__section">
                <div className="page__section__heading">
                  <h2 className="t-gamma t-sans-alt">Get started</h2>
                </div>
                <div className="page__section__content">
                  <p className="t-epsilon t-sans-alt">
                    Think you got what takes to join the team? Feel free to send
                    us your CV and portfolio or code repository at{' '}
                    <a
                      href="mailto:hello@prototyp.digital?subject=I'm applying for an design internship"
                      className="anchor anchor--text"
                    >
                      hello@prototyp.digital
                    </a>
                  </p>
                  <div className="formatted s-top--xlrg">
                    <p>
                      Please note that all code examples that you send need to
                      be committed to git. We will not review any code sent as
                      zip files or via other methods.
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
};
