import React from 'react';
import Slider from 'react-slick';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { AppRoute } from 'const';

export const CaseStudiesWidget: React.FC = () => {
  const [caseStudyWidget, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="case-study-widget" ref={caseStudyWidget}>
      <div className="wrapper">
        <header className="case-study-widget__header">
          <motion.h2
            className="t-beta t-sans-alt"
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 60 }}
            transition={{ ease: 'circOut', duration: 0.6 }}
          >
            Tales of successful products
          </motion.h2>
        </header>
        <Slider {...sliderSettings} className="case-study-widget__content">
          <div className="case-study-widget__item">
            <Link to={AppRoute.PWA} className="case-study-widget__link">
              <picture>
                <source
                  srcSet={require('assets/images/case-studies/pwa/case-study-widget-pwa.webp')}
                  type="image/webp"
                />
                <source
                  srcSet={require('assets/images/case-studies/pwa/case-study-widget-pwa.jpg')}
                  type="image/jpeg"
                />
                <img
                  src={require('assets/images/case-studies/pwa/case-study-widget-pwa.jpg')}
                  alt="PWA audio player"
                />
              </picture>
              <h2 className="t-sans-alt t-delta">PWA audio player</h2>
            </Link>
          </div>
          <div className="case-study-widget__item">
            <Link
              to={AppRoute.InvestorsClub}
              className="case-study-widget__link"
            >
              <picture>
                <source
                  srcSet={require('assets/images/case-studies/investors-club/case-study-widget-investors-club.webp')}
                  type="image/webp"
                />
                <source
                  srcSet={require('assets/images/case-studies/investors-club/case-study-widget-investors-club.jpg')}
                  type="image/jpeg"
                />
                <img
                  src={require('assets/images/case-studies/investors-club/case-study-widget-investors-club.jpg')}
                  alt="Investors Club"
                />
              </picture>
              <h2 className="t-sans-alt t-delta">Investors Club</h2>
            </Link>
          </div>
          <div className="case-study-widget__item">
            <Link to={AppRoute.Tolar} className="case-study-widget__link">
              <picture>
                <source
                  srcSet={require('assets/images/case-studies/tolar/case-study-widget-tolar.webp')}
                  type="image/webp"
                />
                <source
                  srcSet={require('assets/images/case-studies/tolar/case-study-widget-tolar.jpg')}
                  type="image/jpeg"
                />
                <img
                  src={require('assets/images/case-studies/tolar/case-study-widget-tolar.jpg')}
                  alt="Tolar"
                />
              </picture>
              <h2 className="t-sans-alt t-delta">Tolar</h2>
            </Link>
          </div>
        </Slider>
      </div>
    </section>
  );
};
