import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { useRevealAnimation } from 'hooks';
import { SEO } from 'components/shared';
import { CaseStudiesWidget } from 'components/home/fragments/CaseStudiesWidget';
import { CTA } from 'components/home/fragments/CTA';

import { TechStack } from './fragments';

export const ServicesPage: React.FC = () => {
  useRevealAnimation('dark');

  const [servicesProcessItem1Top, servicesProcessItem1TopinView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const [
    servicesProcessItem1Content,
    servicesProcessItem1ContentinView,
  ] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const [servicesProcessItem2Top, servicesProcessItem2TopinView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const [
    servicesProcessItem2Content,
    servicesProcessItem2ContentinView,
  ] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const [servicesProcessItem3Top, servicesProcessItem3TopinView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const [
    servicesProcessItem3Content,
    servicesProcessItem3ContentinView,
  ] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const [servicesProcessItem4Top, servicesProcessItem4TopinView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const [
    servicesProcessItem4Content,
    servicesProcessItem4ContentinView,
  ] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const [servicesCustomerCareRef, servicesCustomerCareRefinView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  return (
    <React.Fragment>
      <SEO
        seoTitle="Services — PROTOTYP"
        seoDescription="We plan, design and build digital narratives combining technology, design, and marketing, helping you to achieve your business goals."
      />
      <section className="services">
        <div className="wrapper">
          <div className="services__header">
            <motion.h1
              className="t-huge t-sans-alt"
              animate={{ opacity: [0, 1], y: [80, 0] }}
              transition={{ ease: 'circOut', duration: 1 }}
            >
              Turn your idea into a marketable product
            </motion.h1>
          </div>
          <div className="w-negativemargo">
            <motion.img
              loading="lazy"
              src={require('assets/images/visuals/build-visual-key.jpg')}
              alt="Turn your idea into a marketable product."
              animate={{ opacity: [0, 1], y: [60, 0] }}
              transition={{ ease: 'circOut', duration: 1, delay: 0.6 }}
            />
          </div>
          <section className="services__process">
            {/* Process item 1 */}
            {/* Process item 1 */}
            {/* Process item 1 */}
            <div className="services__process__item">
              <div
                className="services__process__item__top t-sans-alt"
                ref={servicesProcessItem1Top}
              >
                <motion.span
                  className="t-zeta t-caption--sm"
                  animate={{
                    opacity: servicesProcessItem1TopinView ? 1 : 0,
                    y: servicesProcessItem1TopinView ? 0 : 40,
                  }}
                  transition={{ ease: 'circOut', duration: 0.4 }}
                >
                  01
                </motion.span>
                <motion.h2
                  className="t-zeta t-caption--sm"
                  animate={{
                    opacity: servicesProcessItem1TopinView ? 1 : 0,
                    y: servicesProcessItem1TopinView ? 0 : 40,
                  }}
                  transition={{ ease: 'circOut', duration: 0.4, delay: 0.2 }}
                >
                  Discovery&thinsp;&amp;&thinsp;Strategy
                </motion.h2>
              </div>
              <div
                className="services__process__item__content"
                ref={servicesProcessItem1Content}
              >
                <div className="services__process__item__content__top">
                  <motion.p
                    className="t-sans-alt t-alpha"
                    animate={{
                      opacity: servicesProcessItem1ContentinView ? 1 : 0,
                      y: servicesProcessItem1ContentinView ? 0 : 60,
                    }}
                    transition={{ ease: 'circOut', duration: 0.6 }}
                  >
                    Identify product challenges and goals.
                  </motion.p>
                </div>
                <div className="services__process__item__content__items t-epsilon t-color-ultralight">
                  <motion.p
                    animate={{
                      opacity: servicesProcessItem1ContentinView ? 1 : 0,
                      y: servicesProcessItem1ContentinView ? 0 : 40,
                    }}
                    transition={{ ease: 'circOut', duration: 0.4, delay: 0.4 }}
                  >
                    Identify goals and develop a strategy.
                  </motion.p>
                  <motion.p
                    animate={{
                      opacity: servicesProcessItem1ContentinView ? 1 : 0,
                      y: servicesProcessItem1ContentinView ? 0 : 40,
                    }}
                    transition={{ ease: 'circOut', duration: 0.4, delay: 0.6 }}
                  >
                    Create a product plan and set a budget.
                  </motion.p>
                  <motion.p
                    animate={{
                      opacity: servicesProcessItem1ContentinView ? 1 : 0,
                      y: servicesProcessItem1ContentinView ? 0 : 40,
                    }}
                    transition={{ ease: 'circOut', duration: 0.4, delay: 0.8 }}
                  >
                    Assemble a team of motivated specialists.
                  </motion.p>
                </div>
              </div>
            </div>
            {/* Process item 2 */}
            {/* Process item 2 */}
            {/* Process item 2 */}
            <div className="services__process__item">
              <div
                className="services__process__item__top t-sans-alt"
                ref={servicesProcessItem2Top}
              >
                <motion.span
                  className="t-zeta t-caption--sm"
                  animate={{
                    opacity: servicesProcessItem2TopinView ? 1 : 0,
                    y: servicesProcessItem2TopinView ? 0 : 40,
                  }}
                  transition={{ ease: 'circOut', duration: 0.4 }}
                >
                  02
                </motion.span>
                <motion.h2
                  className="t-zeta t-caption--sm"
                  animate={{
                    opacity: servicesProcessItem2TopinView ? 1 : 0,
                    y: servicesProcessItem2TopinView ? 0 : 40,
                  }}
                  transition={{ ease: 'circOut', duration: 0.4, delay: 0.2 }}
                >
                  Product design
                </motion.h2>
              </div>
              <div
                className="services__process__item__content"
                ref={servicesProcessItem2Content}
              >
                <div className="services__process__item__content__top">
                  <motion.p
                    className="t-sans-alt t-alpha"
                    animate={{
                      opacity: servicesProcessItem2ContentinView ? 1 : 0,
                      y: servicesProcessItem2ContentinView ? 0 : 60,
                    }}
                    transition={{ ease: 'circOut', duration: 0.6 }}
                  >
                    Design a product that gets meaningful results
                  </motion.p>
                </div>
                <div className="services__process__item__content__items t-epsilon t-color-ultralight">
                  <motion.p
                    animate={{
                      opacity: servicesProcessItem2ContentinView ? 1 : 0,
                      y: servicesProcessItem2ContentinView ? 0 : 40,
                    }}
                    transition={{ ease: 'circOut', duration: 0.4, delay: 0.4 }}
                  >
                    Design a goal-driven experience.
                  </motion.p>
                  <motion.p
                    animate={{
                      opacity: servicesProcessItem2ContentinView ? 1 : 0,
                      y: servicesProcessItem2ContentinView ? 0 : 40,
                    }}
                    transition={{ ease: 'circOut', duration: 0.4, delay: 0.6 }}
                  >
                    Create a visually strong, impactful UI.
                  </motion.p>
                  <motion.p
                    animate={{
                      opacity: servicesProcessItem2ContentinView ? 1 : 0,
                      y: servicesProcessItem2ContentinView ? 0 : 40,
                    }}
                    transition={{ ease: 'circOut', duration: 0.4, delay: 0.8 }}
                  >
                    Build a testable prototype.
                  </motion.p>
                </div>
              </div>
              <div className="services__process__item__visual">
                <picture>
                  <source
                    srcSet={require('assets/images/visuals/build-product-design.webp')}
                    type="image/webp"
                  />
                  <source
                    srcSet={require('assets/images/visuals/build-product-design.jpg')}
                    type="image/jpeg"
                  />
                  <img
                    loading="lazy"
                    src={require('assets/images/visuals/build-product-design.jpg')}
                    alt="Product design"
                  />
                </picture>
              </div>
            </div>
            {/* Process item 3 */}
            {/* Process item 3 */}
            {/* Process item 3 */}
            <div className="services__process__item">
              <div
                className="services__process__item__top t-sans-alt"
                ref={servicesProcessItem3Top}
              >
                <motion.span
                  className="t-zeta t-caption--sm"
                  animate={{
                    opacity: servicesProcessItem3TopinView ? 1 : 0,
                    y: servicesProcessItem3TopinView ? 0 : 40,
                  }}
                  transition={{ ease: 'circOut', duration: 0.4 }}
                >
                  03
                </motion.span>
                <motion.h2
                  className="t-zeta t-caption--sm"
                  animate={{
                    opacity: servicesProcessItem3TopinView ? 1 : 0,
                    y: servicesProcessItem3TopinView ? 0 : 40,
                  }}
                  transition={{ ease: 'circOut', duration: 0.4, delay: 0.2 }}
                >
                  Development&thinsp;&amp;&thinsp;Consulting
                </motion.h2>
              </div>
              <div
                className="services__process__item__content"
                ref={servicesProcessItem3Content}
              >
                <div className="services__process__item__content__top">
                  <motion.p
                    className="t-sans-alt t-alpha"
                    animate={{
                      opacity: servicesProcessItem3ContentinView ? 1 : 0,
                      y: servicesProcessItem3ContentinView ? 0 : 60,
                    }}
                    transition={{ ease: 'circOut', duration: 0.6 }}
                  >
                    Execute on strong tech foundations and improve as you scale
                  </motion.p>
                </div>
                <div className="services__process__item__content__items t-epsilon t-color-ultralight">
                  <motion.p
                    animate={{
                      opacity: servicesProcessItem3ContentinView ? 1 : 0,
                      y: servicesProcessItem3ContentinView ? 0 : 40,
                    }}
                    transition={{ ease: 'circOut', duration: 0.4, delay: 0.4 }}
                  >
                    Architect a strong tech foundation.
                  </motion.p>
                  <motion.p
                    animate={{
                      opacity: servicesProcessItem3ContentinView ? 1 : 0,
                      y: servicesProcessItem3ContentinView ? 0 : 40,
                    }}
                    transition={{ ease: 'circOut', duration: 0.4, delay: 0.6 }}
                  >
                    Build a product fast. Go to market.
                  </motion.p>
                  <motion.p
                    animate={{
                      opacity: servicesProcessItem3ContentinView ? 1 : 0,
                      y: servicesProcessItem3ContentinView ? 0 : 40,
                    }}
                    transition={{ ease: 'circOut', duration: 0.4, delay: 0.8 }}
                  >
                    Test, improve and scale-up as you grow.
                  </motion.p>
                </div>
              </div>
              <TechStack />
            </div>
            {/* Process item 4 */}
            {/* Process item 4 */}
            {/* Process item 4 */}
            <div className="services__process__item">
              <div
                className="services__process__item__top t-sans-alt"
                ref={servicesProcessItem4Top}
              >
                <motion.span
                  className="t-zeta t-caption--sm"
                  animate={{
                    opacity: servicesProcessItem4TopinView ? 1 : 0,
                    y: servicesProcessItem4TopinView ? 0 : 40,
                  }}
                  transition={{ ease: 'circOut', duration: 0.4 }}
                >
                  04
                </motion.span>
                <motion.h2
                  className="t-zeta t-caption--sm"
                  animate={{
                    opacity: servicesProcessItem4TopinView ? 1 : 0,
                    y: servicesProcessItem4TopinView ? 0 : 40,
                  }}
                  transition={{ ease: 'circOut', duration: 0.4, delay: 0.2 }}
                >
                  Market performance
                </motion.h2>
              </div>
              <div
                className="services__process__item__content"
                ref={servicesProcessItem4Content}
              >
                <div className="services__process__item__content__top">
                  <motion.p
                    className="t-sans-alt t-alpha"
                    animate={{
                      opacity: servicesProcessItem4ContentinView ? 1 : 0,
                      y: servicesProcessItem4ContentinView ? 0 : 60,
                    }}
                    transition={{ ease: 'circOut', duration: 0.6 }}
                  >
                    Set goals, track and optimize for success
                  </motion.p>
                </div>
                <div className="services__process__item__content__items t-epsilon t-color-ultralight">
                  <motion.p
                    animate={{
                      opacity: servicesProcessItem4ContentinView ? 1 : 0,
                      y: servicesProcessItem4ContentinView ? 0 : 40,
                    }}
                    transition={{ ease: 'circOut', duration: 0.4, delay: 0.4 }}
                  >
                    Integrate advanced analytics tools.
                  </motion.p>
                  <motion.p
                    animate={{
                      opacity: servicesProcessItem4ContentinView ? 1 : 0,
                      y: servicesProcessItem4ContentinView ? 0 : 40,
                    }}
                    transition={{ ease: 'circOut', duration: 0.4, delay: 0.6 }}
                  >
                    Create valuable goals and indicators.
                  </motion.p>
                  <motion.p
                    animate={{
                      opacity: servicesProcessItem4ContentinView ? 1 : 0,
                      y: servicesProcessItem4ContentinView ? 0 : 40,
                    }}
                    transition={{ ease: 'circOut', duration: 0.4, delay: 0.8 }}
                  >
                    Measure, improve and grow your business.
                  </motion.p>
                </div>
              </div>
              <div className="services__process__item__visual">
                <img
                  src={require('assets/images/visuals/build-market-performance.svg')}
                  alt="Market performance"
                />
              </div>
            </div>
          </section>
        </div>
        <div className="services__customercare" ref={servicesCustomerCareRef}>
          <div className="wrapper">
            <div className="services__customercare__layout">
              <header className="services__customercare__header">
                <motion.h2
                  className="t-alpha t-sans-alt"
                  animate={{
                    opacity: servicesCustomerCareRefinView ? 1 : 0,
                    y: servicesCustomerCareRefinView ? 0 : 60,
                  }}
                  transition={{ ease: 'circOut', duration: 0.6 }}
                >
                  Customer&thinsp;&amp;&thinsp;product care
                </motion.h2>
                <motion.p
                  className="t-delta t-sans-alt t-color-light"
                  animate={{
                    opacity: servicesCustomerCareRefinView ? 1 : 0,
                    y: servicesCustomerCareRefinView ? 0 : 40,
                  }}
                  transition={{ ease: 'circOut', duration: 0.4, delay: 0.4 }}
                >
                  Your product is always fully supported and our team is there
                  to help out with technical, business or design questions.
                </motion.p>
                <motion.ol
                  className="t-delta t-sans-alt"
                  animate={{
                    opacity: servicesCustomerCareRefinView ? 1 : 0,
                    y: servicesCustomerCareRefinView ? 0 : 40,
                  }}
                  transition={{ ease: 'circOut', duration: 0.4, delay: 0.8 }}
                >
                  <li>Continuous consulting, support and product care</li>
                  <li>Low friction communication</li>
                  <li>Development and infrastructure cost planning</li>
                </motion.ol>
              </header>
              <motion.div
                className="services__customercare__visual"
                animate={{
                  opacity: servicesCustomerCareRefinView ? 1 : 0,
                  y: servicesCustomerCareRefinView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 1.0 }}
              >
                <picture>
                  <source
                    srcSet={require('assets/images/visuals/build-customer-support.webp')}
                    type="image/webp"
                  />
                  <source
                    srcSet={require('assets/images/visuals/build-customer-support.jpg')}
                    type="image/jpeg"
                  />
                  <img
                    loading="lazy"
                    src={require('assets/images/visuals/build-customer-support.jpg')}
                    alt="Customer & Product care"
                  />
                </picture>
              </motion.div>
            </div>
          </div>
        </div>
        <CaseStudiesWidget />
      </section>
      <CTA />
    </React.Fragment>
  );
};
