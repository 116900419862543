import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import { AppRoute } from 'const';

export const CTA: React.FC = () => {
  const [ctaRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  return (
    <section className="cta" ref={ctaRef}>
      <div className="wrapper">
        <div className="cta__layout">
          <header className="cta__header">
            <motion.h1
              className="t-sans-alt t-beta"
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 60 }}
              transition={{ ease: 'circOut', duration: 0.6 }}
            >
              Ready to rule the market and win the hearts and minds of your
              users?
            </motion.h1>
          </header>
          <div className="cta__content">
            <motion.p
              className="t-sans-alt t-delta"
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.8 }}
            >
              Request a discovery meeting with us to set the course for your
              next big thing. We'd love to hear all about it.
            </motion.p>
            <motion.div
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 1.2 }}
            >
              <Link
                to={AppRoute.Contact}
                className="actioncircle actioncircle--negative"
              >
                Let's talk
              </Link>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};
