import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

export const TechStack: React.FC = () => {
  const [techstackRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  return (
    <div ref={techstackRef} className="services__techstack">
      <div className="services__techstack__container">
        <motion.div
          className="services__techstack__category"
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 60 }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
        >
          <h2 className="t-delta t-sans-alt s-bottom--xxlrg">Frontend</h2>
          <ul className="l">
            <li className="l-unordered__item">React</li>
            <li className="l-unordered__item">Angular</li>
            <li className="l-unordered__item">TypeScript</li>
          </ul>
        </motion.div>
        <motion.div
          className="services__techstack__category"
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 60 }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
        >
          <h2 className="t-delta t-sans-alt s-bottom--xxlrg">Mobile</h2>
          <ul className="l">
            <li className="l-unordered__item">PWA</li>
            <li className="l-unordered__item">React Native</li>
          </ul>
        </motion.div>
        <motion.div
          className="services__techstack__category"
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 60 }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.84 }}
        >
          <h2 className="t-delta t-sans-alt s-bottom--xxlrg">Backend</h2>
          <ul className="l">
            <li className="l-unordered__item">Node.js</li>
            <li className="l-unordered__item">TypeScript</li>
            <li className="l-unordered__item">.NET Core</li>
          </ul>
        </motion.div>
        <motion.div
          className="services__techstack__category"
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 60 }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.84 }}
        >
          <h2 className="t-delta t-sans-alt s-bottom--xxlrg">Database</h2>
          <ul className="l">
            <li className="l-unordered__item">PostgreSQL</li>
            <li className="l-unordered__item">SQL Server</li>
          </ul>
        </motion.div>
        <motion.div
          className="services__techstack__category"
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 60 }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 0.84 }}
        >
          <h2 className="t-delta t-sans-alt s-bottom--xxlrg">Infrastructure</h2>
          <ul className="l-unordered">
            <li className="l-unordered__item">Amazon Web Services</li>
            <li className="l-unordered__item">Google Cloud Platform</li>
            <li className="l-unordered__item">Microsoft Azure</li>
            <li className="l-unordered__item">Firebase</li>
          </ul>
        </motion.div>
        <motion.div
          className="services__techstack__category"
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 60 }}
          transition={{ ease: 'circOut', duration: 0.6, delay: 1.0 }}
        >
          <h2 className="t-delta t-sans-alt s-bottom--xxlrg">DevOps</h2>
          <ul className="l">
            <li className="l-unordered__item">Docker</li>
            <li className="l-unordered__item">Kubernetes</li>
            <li className="l-unordered__item">CircleCI</li>
            <li className="l-unordered__item">Azure DevOps</li>
            <li className="l-unordered__item">Github Actions</li>
          </ul>
        </motion.div>
      </div>
    </div>
  );
};
