import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import Slider from 'react-slick';

export const Dribbble: React.FC = () => {
  const [dribbbleRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });
  const [showDribbleVideo, setShowDribbleVideo] = useState(false);
  const dribbleVideoRef = useRef<HTMLDivElement>(null);
  const dribbleVideoObserver = new IntersectionObserver(
    onDribbleVideoIntersection,
    {
      rootMargin: '0px',
      threshold: 0.25,
    },
  );

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: false,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
    ],
  };

  function onDribbleVideoIntersection(entries: IntersectionObserverEntry[]) {
    if (!entries || entries.length <= 0) {
      return;
    }

    if (entries[0].isIntersecting) {
      setShowDribbleVideo(true);
      dribbleVideoObserver.disconnect();
    }
  }

  useEffect(() => {
    if (dribbleVideoRef && dribbleVideoRef.current) {
      dribbleVideoObserver.observe(dribbleVideoRef.current);
    }
  }, [dribbleVideoRef, dribbleVideoObserver]);

  return (
    <section ref={dribbbleRef} className="dribbble">
      <div className="wrapper">
        <header className="dribbble__heading">
          <div className="dribbble__heading__content">
            <motion.h1
              className="t-alpha t-sans-alt"
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 60 }}
              transition={{ ease: 'circOut', duration: 0.6 }}
            >
              Interface Experiments
            </motion.h1>
          </div>
          <div className="dribbble__heading__action">
            <a
              className="anchor anchor--negative"
              href="https://dribbble.com/prototyp"
            >
              Check us out on Dribbble
            </a>
          </div>
        </header>
      </div>

      <motion.div
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 60 }}
        transition={{ ease: 'circOut', duration: 1, delay: 0.8 }}
      >
        <div className="wrapper" ref={dribbleVideoRef}>
          {showDribbleVideo && (
            <Slider {...sliderSettings} className="dribbble__slider">
              <div className="dribbble__slider__item">
                <video
                  width="100%"
                  muted={true}
                  controls
                  poster="https://cdn.dribbble.com/users/1467283/screenshots/7797893/media/245ced7e0d4a027a55d8da85b1a0cf82.png?compress=1&resize=800x600"
                  className="dribble__slider__video-player"
                >
                  <source
                    src="https://cdn.dribbble.com/users/1467283/screenshots/7797893/media/c5981b783ace247a55a25f71a75c3fc0.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
              <div className="dribbble__slider__item">
                <video
                  width="100%"
                  muted={true}
                  controls
                  poster="https://cdn.dribbble.com/users/1467283/screenshots/9527902/media/e192d206c508e2d74d01e1b31c89b773.png?compress=1&resize=1600x1200"
                  className="dribble__slider__video-player"
                >
                  <source
                    src="https://cdn.dribbble.com/users/1467283/screenshots/9527902/media/6ec3867b0020d776c17bcc2542e0f2cf.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </Slider>
          )}
        </div>
      </motion.div>
    </section>
  );
};
