import React from 'react';

import { AppRoute } from 'const';

import { CaseStudyItem } from './CaseStudyItem';

export const CaseStudies: React.FC = () => {
  return (
    <section className="case-study">
      <div className="case-study__item__list">
        <CaseStudyItem
          route={AppRoute.HappiSeek}
          client="HappiSeek"
          title="Audio-podcast app that will shift your perception and guide you towards happiness."
          numerator="01"
          category="Audio podcast app"
          imgSrc={require('assets/images/case-studies/happiseek/case-list-happiseek.jpg')}
          imgSrcSet={require('assets/images/case-studies/happiseek/case-list-happiseek@sm.jpg')}
          imgAlt="HappiSeek"
          bgColor="happiseek"
          btnColor="light"
        />

        <CaseStudyItem
          route={AppRoute.InvestorsClub}
          client="Investors Club"
          title="Online business marketplace that disrupted the market on day one. "
          numerator="02"
          category="Hybrid desktop app"
          imgSrc={require('assets/images/case-studies/investors-club/case-list-investors-club.jpg')}
          imgSrcSet={require('assets/images/case-studies/investors-club/case-list-investors-club@sm.jpg')}
          imgAlt="Investors Club"
          bgColor="investorsclub"
          btnColor="primary"
        />

        <CaseStudyItem
          route={AppRoute.Tolar}
          client="Tolar"
          title="A revolutionary blockchain technology explorer and desktop wallet."
          numerator="03"
          category="Product design and development"
          imgSrc={require('assets/images/case-studies/tolar/case-list-tolar.jpg')}
          imgSrcSet={require('assets/images/case-studies/tolar/case-list-tolar@sm.jpg')}
          imgAlt="Tolar"
          bgColor="tolar"
          btnColor="light"
        />

        <CaseStudyItem
          route={AppRoute.PWA}
          client="PROTOTYP"
          title="Keep listening to music & podcasts from the browser when on the move."
          numerator="04"
          category="PWA App"
          imgSrc={require('assets/images/case-studies/pwa/case-list-pwa.jpg')}
          imgSrcSet={require('assets/images/case-studies/pwa/case-list-pwa@sm.jpg')}
          imgAlt="PWA"
          bgColor="pwa"
          btnColor="primary"
        />
      </div>
    </section>
  );
};
