import React from 'react';
import { Helmet } from 'react-helmet-async';

export interface SEOProps {
  seoTitle: string;
  seoDescription: string;
  imagePath?: string;
}

export const SEO: React.FC<SEOProps> = ({
  seoTitle,
  seoDescription,
  imagePath,
}) => (
  <Helmet>
    <title>{seoTitle}</title>
    <meta charSet="utf-8" />
    <link rel="canonical" href={window.location.href} />
    <meta name="description" content={seoDescription} />​
    {/* Open Graph metadata */}
    <meta property="og:title" content={seoTitle} />
    <meta property="og:description" content={seoDescription} />
    <meta property="og:url" content={window.location.href} />
    <meta
      property="og:image"
      content={
        imagePath || 'https://prototyp.digital/share/general-facebook-share.png'
      }
    />
    {/* Twitter metadata */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={seoTitle} />
    <meta name="twitter:description" content={seoDescription} />
    <meta name="twitter:url" content={window.location.href} />
    <meta
      name="twitter:image"
      content={
        imagePath || 'https://prototyp.digital/share/general-twitter-share.png'
      }
    />
  </Helmet>
);
