import React from 'react';
import InView from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { defaultOpeningsSections } from 'fixtures/defaultOpeningsSections';

export const DefaultNextSteps: React.FC = () => {
  return (
    <>
      {defaultOpeningsSections.nextSteps.map(({ title, description }, i) => (
        <InView key={title} triggerOnce={true} threshold={0.5}>
          {({ ref, inView }) => (
            <motion.li
              ref={ref}
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
            >
              <span className="opening__list__number t-color-light">
                0{i + 1}
              </span>
              <h3 className="opening__list__title t-delta t-sans-alt">
                {title}
              </h3>
              <p className="opening__list__description--secondary t-epsilon t-color-ultralight">
                {description}
              </p>
            </motion.li>
          )}
        </InView>
      ))}
    </>
  );
};
