import React from 'react';
import { NavLink } from 'react-router-dom';

import { AppRoute } from 'const';

interface Props {
  isNavOpen: boolean;
  isAltHeader: boolean;
  onClose: VoidFunction;
}

export const Navigation: React.FC<Props> = ({
  isNavOpen,
  isAltHeader,
  onClose,
}) => {
  let navClasses = 'nav';
  if (isNavOpen) navClasses += ' is-visible';
  if (isAltHeader) navClasses += ' nav--alt';

  return (
    <nav className={navClasses}>
      <ol className="nav__wrapper">
        <li className="nav__item nav__item--home">
          <NavLink
            exact
            onClick={onClose}
            className="nav__link"
            activeClassName="active"
            data-hover="Home"
            to={AppRoute.Homepage}
          >
            <span>Home</span>
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink
            onClick={onClose}
            className="nav__link"
            activeClassName="active"
            data-hover="Build a product"
            to={AppRoute.Services}
          >
            <span>Build a product</span>
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink
            onClick={onClose}
            className="nav__link"
            activeClassName="active"
            data-hover="Partner with us"
            to={AppRoute.Company}
          >
            <span>Partner with us</span>
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink
            onClick={onClose}
            className="nav__link"
            activeClassName="active"
            data-hover="Careers"
            to={AppRoute.Openings}
          >
            <span>Careers</span>
          </NavLink>
        </li>
        <li className="nav__item">
          <NavLink
            onClick={onClose}
            className="nav__link nav__link--contact"
            activeClassName="active"
            data-hover="Schedule a meeting"
            to={AppRoute.Contact}
          >
            <span>Schedule a meeting</span>
          </NavLink>
        </li>
      </ol>
    </nav>
  );
};
