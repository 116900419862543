import React from 'react';

interface Props {
  name: string;
  position: string;
}

export const TeamMember: React.FC<Props> = ({ name, position }) => (
  <li className="team__list__item t-sans-alt">
    <h3 className="t-epsilon">{name}</h3>
    <p className="t-epsilon t-color-light">{position}</p>
  </li>
);
