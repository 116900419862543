export interface DefaultOpeningsSections {
  benefits: { title: string; description: string }[];
  nextSteps: { title: string; description: string }[];
}

export const defaultOpeningsSections: DefaultOpeningsSections = {
  benefits: [
    {
      title: 'Mentorship',
      description:
        'You will get a dedicated mentor to help you with the onboarding process and to help you develop your skills and become the best you. Usually, someone from the team with a bit more experience helps you onboard and get familiarised with how we do things.',
    },
    {
      title: 'Education',
      description:
        'We have workshops where you can learn something new from your colleagues. Also, you can choose the conferences and workshops that you would like to attend locally and internationally. We invest in your education by getting books, e-books, paid lessons, certificates, and anything else you might need.',
    },
    {
      title: 'Growth',
      description:
        "Every project is an opportunity to grow and expand your knowledge. We'll help you with your personal and professional growth. You can align your professional growth and position to what makes you tick in the long run.",
    },
    {
      title: 'People & work atmosphere',
      description:
        'We nurture good and honest personal relationships. We love fresh ideas and base our project decisions on objectivity. People on the team love technology, design, and business. Also coffee is great and animal friends are welcome.',
    },
    {
      title: 'Office & ergonomics',
      description:
        "Our office is a nice place to be if you want it. It's fully equipped and a fun place to be. If you would rather stay at home, you can work from your home office. You get to choose your equipment and gadgets and customize your ergonomic chair",
    },
    {
      title: 'Remote work & flexible hours',
      description:
        'You choose how you want to work. Feel free to hit the office from time to time or work from home (or from wherever). We also help you with setting up your home office for remote work. Set your working hours to match your lifestyle and productivity.',
    },
    {
      title: 'Vacations and free days',
      description:
        'Take a vacation, as long as you need it, no strings attached. Also, when we have the chance, we connect holidays with weekends. We also have a long winter break to give all of us time to chill out with friends and families.',
    },
    {
      title: 'Take ownership of your career',
      description:
        'Together with each new person that arrives at the team, we create a growth plan to match their financial, professional, and personal needs. We check that plan every couple of months to see if we are on the right track.',
    },
  ],
  nextSteps: [
    {
      title: 'Showcase your best work',
      description:
        "We value initiative and willingness to learn, that's why we take our time to check your portfolio & git repository.",
    },
    {
      title: "Let's get practical",
      description:
        'Depending on your work and skills, our team will send you a small assignment that helps us understand your way of work and level of knowledge.',
    },
    {
      title: "Let's meet",
      description:
        "We'll provide you with feedback and support for your assignment. Once that's done we can grab a cup of coffee and discuss your professional plans.",
    },
  ],
};
