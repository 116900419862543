export interface OpeningDetails {
  position: string;
  description: string;
  lookingFor: string[];
  bonusPoints?: string[];
  blockquote?: {
    text: string;
    authorName: string;
    authorPosition: string;
    authorImagePath: string;
  };
  seoTitle: string;
  seoDescription: string;
  seoImagePath?: string;
  sendUsMessage?: string;
  roangoLink?: string;
  benefits?: { title: string; description: string }[];
  nextSteps?: { title: string; description: string }[];
}

export const openings: { [id: string]: OpeningDetails } = {
  'interface-designer': {
    position: 'Interface Designer',
    seoTitle: "We're looking for an Interface Designer | PROTOTYP",
    seoDescription:
      'We are always on the lookout for innovators and awesome people. Reach out if you are looking for a new opportunity.',
    description:
      'We are looking for a skilled creative who is in love with the design and digital technologies and has a good understanding of UX and UI principles. The candidate should be able to solve design problems with creative and visually appealing solutions. The Interface designer’s job is to understand the project, create wireframes, map out user journeys, and design interfaces people love to use.',
    lookingFor: [
      'great analytical, research and problem solving skills',
      'excellent design principles and typography knowledge',
      'sharp communication and presentational skills',
      'good understanding and practical usage of latest design tools (Adobe, Sketch...)',
      'a portfolio that showcases great visual skills and problem solving process',
      'excellent understanding of responsive design',
      'understanding of user-centered design, interactive design and user experience',
      'practical and fluent use of English',
      'motivation & great team spirit ',
    ],
    blockquote: {
      text:
        'Our design team nurtures a culture of a test-and-learn approach that helps us identify opportunities for improvement. With that mindset, we constantly improve ourselves and our client’s projects worldwide. You will perfectly fit our team if you are skilled, creative, open-minded, and have strong design thinking toward digital technologies. Join us, and let’s design awesome products together!',
      authorName: 'Ivo',
      authorPosition: 'Design Lead',
      authorImagePath: 'ivo-anic',
    },
    seoImagePath:
      'https://prototyp.digital/share/job-ad-interface-designer.png',
    roangoLink:
      'https://www.roango.com/public-opening/prototyp/interface_designer-UiufPIrR5fXUQ',
  },
  'interface-developer': {
    position: 'Interface Developer',
    seoTitle: "We're looking for an Interface Developer | PROTOTYP",
    seoDescription:
      'We are always on the lookout for innovators and awesome people. Reach out if you are looking for a new opportunity.',
    description:
      "We are looking for an Interface developer, a skilled individual in love with modern client-side technologies, able to quickly adapt to new standards and market requirements. The Interface developer's job is to understand and implement solutions in a variety of technologies such as JavaScript, TypeScript, React, React Native, and NodeJS that are user-friendly, visually appealing, and performant.",
    lookingFor: [
      'great analytical, research and problem solving skills',
      'excellent JavaScript knowledge',
      'TypeScript experience, and understanding of generics in development',
      'excellent browser API knowledge',
      'experience with PWA features such as: LocalStorage, IndexDB, ServiceWorkers ...',
      'understanding of React/Redux architecture and DDD design pattern',
      'experience with the usage of REST APIs',
      'experience with usage and setup of JavaScript build tools and package managers',
      'good understanding of user experience and web performance',
      'basic git experience',
      'a showcase of code that showcases your skills',
      'practical and fluent use of English',
      'motivation & great team spirit',
    ],
    blockquote: {
      text:
        'You won’t be a task-monkey, but an independent developer from whom we expect to check up on our work as much as we teach you to do yours. It’s a position where standing still is falling behind, as we try to be cutting-edge in everything we do.',
      authorName: 'Sebastijan',
      authorPosition: 'Web Lead',
      authorImagePath: 'sebastijan-dumancic',
    },
    bonusPoints: [
      'experience with databases, and NoSQL',
      'experience with Firebase or AWS Lambda',
      'NodeJS/express experience',
      'experience with mobile development and deployment processes',
    ],
    seoImagePath:
      'https://prototyp.digital/share/job-ad-interface-developer-v2.png',
    roangoLink:
      'https://www.roango.com/public-opening/prototyp/interface_developer-n5WF3C4hZNJjM',
  },
  'react-native-developer': {
    position: 'React Native Developer',
    seoTitle: "We're looking for a React Native Developer | PROTOTYP",
    seoDescription:
      'We are always on the lookout for innovators and awesome people. Reach out if you are looking for a new opportunity.',
    description:
      'We are looking for a React Native developer, a skilled individual in love with modern client-side technologies, able to quickly adapt to new standards and market requirements. The React Native developer’s job is to understand and implement hybrid solutions (Android & iOS) in React Native & TypeScript that are user-friendly, visually appealing, and performant.',
    lookingFor: [
      'great analytical, research and problem solving skills',
      'excellent JavaScript knowledge',
      'TypeScript experience, and understanding of generics in development',
      'basic Android & iOS native knowledge (linking libraries, installing CocoaPods, etc)',
      'experience with AsyncStorage',
      'understanding of React/Redux architecture and DDD design pattern',
      'experience with the usage of REST APIs',
      'experience with usage and setup of JavaScript build tools and package managers',
      'good understanding of user experience and mobile app practices',
      'basic git experience',
      'a showcase of code that showcases your skills',
      'practical and fluent use of English',
      'motivation & great team spirit',
    ],
    blockquote: {
      text:
        'You will not be working for us, you will be working with us - bringing all our collective knowledge to the forefront and making sure that we are always proud of what we’ve created. Just don’t ask the Android vs iOS question.',
      authorName: 'Luka',
      authorPosition: 'Lead Mobile Developer',
      authorImagePath: 'luka-buljan',
    },
    bonusPoints: [
      'experience with databases, and NoSQL',
      'experience with Firebase or AWS Lambda',
      'NodeJS/express experience',
      'experience with mobile development and deployment processes',
    ],
    seoImagePath:
      'https://prototyp.digital/share/job-ad-interface-developer-v2.png',
    sendUsMessage: 'Send us your CV and your git repository to',
  },
  'qa-engineer': {
    position: 'QA Engineer',
    seoTitle: "We're looking for a QA Engineer I PROTOTYP",
    seoDescription:
      'We are always on the lookout for great people like you. Reach out and start your career as a QA Engineer.',
    description:
      "We are looking for a QA engineer, a skilled individual with an analytical mind, and a good understanding of how things work under the hood, not afraid to offer suggestions on how to improve them. QA engineer's job is testing out solutions before they get to end-users, automate repetitive tasks and suggest improvements and processes that increase the quality of our products.",
    lookingFor: [
      'a good understanding of what quality software is',
      'analytical approach to things',
      'ability to present problems in a concise and concrete manner',
      'web application testing',
      'mobile application testing',
      'basics of REST API communication',
      'understanding of and writing test plans',
      'understanding software release process',
      'git experience',
    ],
    blockquote: {
      text:
        'Quality is an ever-present aspect of all parts of development which we strive to perfect. You will be a valuable team member who pushes others to improve and optimize their work. The quality standard is high, and we expect you to push it even further. If even the most minuscule issues deprive you of sleep until they get resolved, you’ll be a great fit!',
      authorName: 'Matej',
      authorPosition: 'QA Engineer',
      authorImagePath: 'matej-dusic',
    },
    bonusPoints: [
      'JavaScript experience',
      'experience with Firebase and or AWS',
      'experience with e2e testing software (cypress, postman, webdriver ...)',
    ],
    seoImagePath: 'https://prototyp.digital/share/job-ad-qa.png',
    sendUsMessage: 'Send us your CV and your git repository to',
  },
  'angular-developer': {
    position: 'Angular Developer',
    seoTitle: "We're looking for an Angular Developer | PROTOTYP",
    seoDescription:
      'We are always on the lookout for innovators and awesome people. Reach out if you are looking for a new opportunity.',
    description:
      "We are looking for an Angular developer, a skilled individual in love with modern client-side technologies, able to adapt quickly to new standards and market requirements. The Angular developer's job is to understand and implement solutions within a variety of technologies such as JavaScript, TypeScript, Angular, and NodeJS that are user-friendly, visually appealing, and performant.",
    lookingFor: [
      'great analytical, research and problem solving skills',
      'excellent JavaScript knowledge',
      'TypeScript experience, and understanding of generics in development',
      'excellent browser API knowledge',
      'experience with PWA features such as: LocalStorage, IndexDB, ServiceWorkers ...',
      'understanding of Modular Angular architecture',
      'experience with the usage of REST APIs',
      'experience with usage and setup of JavaScript build tools and package managers',
      'good understanding of user experience and web performance',
      'basic git experience',
      'a showcase of code that showcases your skills',
      'practical and fluent use of English',
      'motivation & great team spirit',
    ],
    blockquote: {
      text:
        'We aim to write code that is performant and enjoyable to read in cutting-edge technologies. How will you know if we are a perfect match? All you have to is be determined and strive to transform your ideas into great solutions.',
      authorName: 'Josip',
      authorPosition: 'Developer',
      authorImagePath: 'josip-ravas',
    },
    bonusPoints: [
      'good understanding of RxJS',
      'experience with developing and publishing Angular libraries',
      'experience with Angular Material and Angular Material custom theme development',
      'experience with databases, and NoSQL',
      'experience with Firebase or AWS Lambda',
      'NodeJS/express experience',
      'experience with mobile development and deployment processes',
    ],
    seoImagePath: 'https://prototyp.digital/share/job-ad-angular-developer.png',
    sendUsMessage: 'Send us your CV and your git repository to',
  },
  'senior-designer': {
    position: 'Senior/Mid Interface Designer ',
    seoTitle: "We're looking for a Senior/Mid Interface Designer | PROTOTYP",
    seoDescription: 'Design products that solve problems and delight users',
    description:
      'We are looking for a skilled creative who is in love with design and digital technologies and has a good understanding of UX and UI principles. The candidate should be able to solve design problems with creative and visually appealing solutions. The Interface designer’s job is to understand the project, create wireframes, map out user journeys, and design interfaces people love to use. ',
    lookingFor: [
      'great analytical, research, and problem-solving skills',
      'excellent design principles and typography knowledge',
      'sharp communication and presentational skills',
      'good understanding and practical usage of the latest design tools (Figma, Adobe, Sketch...)',
      'a portfolio that showcases great visual skills and problem-solving process',
      'excellent understanding of responsive design',
      'understanding of user-centered design, interactive design, and user experience',
      'practical and fluent use of English',
      'motivation & great team spirit',
    ],
    benefits: [
      {
        title: 'Mentorship',
        description:
          'You will get a dedicated mentor to help you with the onboarding process and to help you develop your skills and become the best you. Usually, someone from the team with a bit more experience helps you onboard and get familiarised with how we do things.',
      },
      {
        title: 'Education',
        description:
          'We have workshops where you can learn something new from your colleagues. Also, you can choose the conferences and workshops that you would like to attend locally and internationally. We invest in your education by getting books, e-books, paid lessons, certificates, and anything else you might need.',
      },
      {
        title: 'Growth',
        description:
          "Every project is an opportunity to grow and expand your knowledge. We'll help you with your personal and professional growth. You can align your professional growth and position to what makes you tick in the long run. ",
      },
      {
        title: 'People & work atmosphere',
        description:
          'We nurture good and honest personal relationships. We love fresh ideas and base our project decisions on objectivity. People on the team love technology, design, and business. Also coffee is great and animal friends are welcome.',
      },
      {
        title: 'Office & ergonomics',
        description:
          "Our office is a nice place to be if you want it. It's fully equipped and a fun place to be. If you would rather stay at home, you can work from your home office. You get to choose your equipment and gadgets and customize your ergonomic chair",
      },
      {
        title: 'Remote work & flexible hours',
        description:
          'You choose how you want to work. Feel free to hit the office from time to time or work from home (or from wherever). We also help you with setting up your home office for remote work. Set your working hours to match your lifestyle and productivity.',
      },
      {
        title: 'Vacations and free days',
        description:
          'Take a vacation, as long as you need it, no strings attached. Also, when we have the chance, we connect holidays with weekends. We also have a long winter break to give all of us time to chill out with friends and families. ',
      },
      {
        title: 'Take ownership of your career',
        description:
          'Together with each new person that arrives at the team, we create a growth plan to match their financial, professional, and personal needs. We check that plan every couple of months to see if we are on the right track.',
      },
    ],
    nextSteps: [
      {
        title: 'Showcase your best work',
        description:
          "We value initiative and willingness to learn, that's why we take our time to check your portfolio & your work.",
      },
      {
        title: "Let's get practical",
        description:
          'Depending on your work and skills, our team will send you a small assignment that helps us understand your way of work and level of knowledge.',
      },
      {
        title: "Let's meet",
        description:
          "We'll provide you with feedback and support for your assignment. Once that's done we can grab a cup of coffee and discuss your professional plans.",
      },
    ],
    blockquote: {
      text:
        'Our design team nurtures a culture of a test-and-learn approach that helps us identify opportunities for improvement. With that mindset, we constantly improve ourselves and our client’s projects worldwide. You will perfectly fit our team if you are skilled, creative, open-minded, and have strong design thinking toward digital technologies. Join us, and let’s design awesome products together!',
      authorName: 'Ivo',
      authorPosition: 'Design Lead',
      authorImagePath: 'ivo-anic',
    },
    seoImagePath: 'https://prototyp.digital/share/job-ad-web-designer.png',
    sendUsMessage: 'Send us your CV and portfolio to',
  },
  'backend-developer-php': {
    position: 'Backend Developer (PHP/Craft CMS)',
    seoTitle:
      "We're looking for a Backend Developer (PHP/Craft CMS) | PROTOTYP",
    seoDescription:
      'Apply for a Backend Developer (PHP/Craft CMS) role and help us build and deliver highly performant solutions.',
    description:
      "The backend developer's job is to write scalable, testable, and performant PHP-based service logic and APIs. Our core PHP stack is based on the Craft CMS, Docker, Yii, and Laravel Sail frameworks connected to AWS services.",
    lookingFor: [
      'great analytical, research and problem solving skills',
      'excellent PHP knowledge',
      'good knowledge of SQL',
      'experience with at least one ORM for PHP applications',
      'experience with building REST API services',
      'experience with architectural patterns in backend development',
      'experience with usage and setup of PHP build tools and package managers',
      'basic git experience',
      'a showcase of code that showcases your skills',
      'practical and fluent use of English',
      'motivation & great team spirit',
    ],
    bonusPoints: [
      'Craft CMS experience',
      'experience with Doctrine PHP',
      'experience with Yii, Laravel, or Symphony frameworks and their architectural style',
      'experience with building microservices',
      'experience with MQ and Caching systems (RabbitMQ, Redis)',
      'experience with building GraphQL (code or schema first) solutions',
      'experience with more than one database server',
      'experience with AWS',
      'experience with Docker',
      'experience with Kubernetes',
    ],
    blockquote: {
      text:
        'You will be able to work on projects for our big international clients that provide services in a multitude of countries and have big content requirements, and in turn, we will help you grow your expertise in delivering and fine-tuning quality content backends that push the boundaries of what we do at the company.',
      authorName: 'Vlatko',
      authorPosition: 'CTO',
      authorImagePath: 'vlatko-vlahek',
    },
    seoImagePath:
      'https://prototyp.digital/share/job-ad-backend-developer-php.png',
    roangoLink:
      'https://www.roango.com/public-opening/prototyp/backend_developer_php_craftcms_-0DMZbfVD7fvMLg',
  },
  'backend-developer-nodejs': {
    position: 'Backend Developer (NodeJS)',
    seoTitle: "We're looking for a Backend Developer (NodeJS) | PROTOTYP",
    seoDescription:
      'Apply for a Backend Developer (NodeJS) role and help us build and deliver highly performant solutions.',
    description:
      'We are looking for a NodeJS backend developer, a skilled individual that thrives on creating quality services that power the applications we distribute to our clients. The backend developer’s job is to write scalable, testable and performant APIs and services that serve as a backbone for the services we provide to our clients. Our core stack is based on the nest.js framework and utilizes TypeScript, express, sequelize, PostgreSQL, Cypress, Mocha and Docker.',
    lookingFor: [
      'great analytical, research and problem solving skills',
      'excellent JavaScript knowledge',
      'excellent node.js knowledge',
      'good knowledge of SQL',
      'experience with at least one ORM for JavaScript',
      'experience with building REST API services',
      'experience with architectural patterns in JavaScript',
      'experience with versioning API-s and providing detailed exceptions to users',
      'experience with usage and setup of JavaScript build tools and package managers',
      'basic git experience',
      'a showcase of code that showcases your skills',
      'practical and fluent use of English',
      'motivation & great team spirit',
    ],
    bonusPoints: [
      'TypeScript experience, and a good understanding of generics in the development',
      'NoSQL experience',
      'experience with MQ and Caching systems (RabbitMQ, Redis)',
      'experience with decorators and patterns of aspect-oriented programming',
      'experience with building microservices',
      'experience with building GraphQL (code or schema first) solutions',
      'experience with more than one database server',
      'experience with Firebase or AWS Lambda',
      'experience with Docker',
      'experience with Kubernetes',
    ],
    blockquote: {
      text:
        'You will empower the rest of our team with a strong foundation for delivering performant, quality solutions, and in turn, we will help you grow your expertise in delivering bespoke, cutting-edge solutions that push the boundaries of what we do at the company.',
      authorName: 'Vlatko',
      authorPosition: 'CTO',
      authorImagePath: 'vlatko-vlahek',
    },
    seoImagePath:
      'https://prototyp.digital/share/job-ad-backend-developer-nodejs.png',
    roangoLink:
      'https://www.roango.com/public-opening/prototyp/backend_developer_nodejs_-GwV0NB0tMDlxA',
  },
  'project-manager': {
    position: 'Project Manager',
    seoTitle: "We're looking for a Project Manager | PROTOTYP",
    seoDescription:
      'Apply for a Project Manager role and help us build and deliver highly performant solutions.',
    description:
      'We are looking for a Project Manager, a skilled and organized individual responsible for planning and overseeing projects. As a Project manager at PROTOTYP, your job will be to define the scope of projects, track and manage project implementation (project schedule, issues, and risks), work closely with the design and dev team, and manage customer expectations.',
    lookingFor: [
      'excellent analytical, research, and problem-solving skills',
      'excellent communication skills',
      'good understanding of project objectives and scope',
      'knowledge of resources planning and monitoring',
      'knowledge of industry best practices, techniques, and standards',
      'understanding of monitoring progress and measuring project performance',
      'good understanding of user experience and web performance',
      'work experience in project management',
      'practical and fluent use of English language',
      'motivation & great team spirit',
    ],
    bonusPoints: [
      'experience with project management tools',
      'project management certificates',
      'good understanding of the design & development process',
      'knowledge of additional foreign languages',
    ],
    blockquote: {
      text:
        'We approach each project individually and with great care. Taking care of project progress and your team will help you learn new skills and make you strive for excellence. If you are up for a challenge, come and join our team!',
      authorName: 'Valentina',
      authorPosition: 'Project Manager',
      authorImagePath: 'valentina-bermanec',
    },
    nextSteps: [
      {
        title: 'Showcase your best work',
        description:
          "We value initiative and willingness to learn, that's why we take our time to check your CV.",
      },
      {
        title: "Let's get practical",
        description:
          'Depending on your work and skills, our team will send you a small assignment that helps us understand your way of work and level of knowledge.',
      },
      {
        title: "Let's meet",
        description:
          "We'll provide you with feedback and support for your assignment. Once that's done we can grab a cup of coffee and discuss your professional plans.",
      },
    ],
    seoImagePath: 'https://prototyp.digital/share/job-ad-project-manager.png',
    roangoLink:
      'https://www.roango.com/public-opening/prototyp/project_manager-8Q8xMZrcWgTmJs',
  },
  'open-application': {
    position: 'Open application',
    seoTitle: "Apply if you think you're great | PROTOTYP",
    seoDescription: 'Apply for a any role and build great stuff with us.',
    description:
      "While we are not actively looking for a new team member, we know there is top talent waiting to be found. If you think you got what it takes, send us a message and let's talk!",
    lookingFor: [
      'great analytical, research and problem solving skills',
      'excellent technical knowledge',
      'excellent design principles and typography knowledge',
      'sharp communication and presentational skills',
      'a showcase of code that showcases your skills',
      'knowledge of industry best practices, techniques, and standards',
      'practical and fluent use of English',
    ],
    blockquote: {
      text:
        'You will empower the rest of our team with a strong skillset and in turn, we will help you learn and grow.',
      authorName: 'Vlatko',
      authorPosition: 'CTO',
      authorImagePath: 'vlatko-vlahek',
    },
    seoImagePath: 'https://prototyp.digital/share/general-facebook-share.png',
    roangoLink:
      'https://www.roango.com/public-opening/prototyp/open_application-qEAJyXaLRYw3g',
  },
};
