import { AppRoute } from './app-routes';

export const OpeningRoutes = [
  AppRoute.InterfaceDeveloper,
  // AppRoute.InterfaceDesigner,
  AppRoute.ReactNativeDeveloper,
  AppRoute.QAEngineer,
  AppRoute.AngularDeveloper,
  AppRoute.BackendDeveloperNodeJS,
  AppRoute.BackendDeveloperPHP,
  AppRoute.SeniorDesigner,
  AppRoute.ProjectManager,
  AppRoute.OpenApplication,
];
