import React from 'react';
import { motion } from 'framer-motion';

export const Hero: React.FC = () => {
  const handleScrollToCaseStudies = (
    e: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    const id = e.currentTarget?.href ?? '';
    const target = document.getElementById(id.substring(id.indexOf('#') + 1));

    if (!target) {
      return;
    }

    e.preventDefault();
    const top = window.scrollY + target.getBoundingClientRect().top;
    window.scrollTo({ top, behavior: 'smooth' });
    window.history.pushState(null, '', `#${id.substring(id.indexOf('#') + 1)}`);
  };

  return (
    <section className="hero">
      <div className="hero__wrapper">
        <header className="hero__claim">
          <motion.h1
            className="hero__title t-huge t-sans-alt"
            animate={{ opacity: [0, 1], y: [60, 0] }}
            transition={{ ease: 'circOut', duration: 1 }}
          >
            Products done right.
          </motion.h1>
          <motion.a
            href="#caseStudies"
            className="hero__more anime--opacity"
            animate={{ opacity: [0, 1], y: [60, 0] }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
            onClick={handleScrollToCaseStudies}
          >
            <span className="t-zeta t-sans-alt">Discover ↓</span>
            <svg height="160" width="160" className="hero__more__circle">
              <circle cx="80" cy="80" r="79" />
            </svg>
          </motion.a>
        </header>
      </div>
      <motion.div
        className="hero__visual"
        animate={{ opacity: [0, 1] }}
        transition={{ ease: 'linear', duration: 1, delay: 0.6 }}
      />
      <div className="hero__visualoverlay" />
    </section>
  );
};
