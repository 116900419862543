import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { CookiePolicy, AppLayout, Routing } from 'components/shared';

export const App: React.FC = () => (
  <HelmetProvider>
    <BrowserRouter>
      <AppLayout>
        <Routing />
        <CookiePolicy />
      </AppLayout>
    </BrowserRouter>
  </HelmetProvider>
);
