import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { OpeningDetails, openings } from 'fixtures/openings';
import { SEO } from 'components/shared';
import { useRevealAnimation } from 'hooks';
import { AppRoute, OpeningRoutes } from 'const';
import { ReactComponent as BlockquoteSvg } from 'assets/icons/blockquote.svg';

import {
  CustomBenefits,
  CustomNextSteps,
  DefaultBenefits,
  DefaultNextSteps,
} from './fragments';

export const OpeningDetailsPage: React.FC = () => {
  useRevealAnimation('dark');
  const [openingsExpectationsRef, openingsExpectationsRefInView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const [openingsBenefitsRef, openingsBenefitsRefInView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const [openingBlockquoteRef, openingBlockquoteRefInView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const [openingGetStartedRef, openingGetStartedRefInView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const [openingCTARef, openingCTARefInView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  const { id } = useParams<{ id: string }>();
  const isIdValid = OpeningRoutes.find(route => route === `/openings/${id}`);
  if (!id || !isIdValid) {
    return <Redirect to={`/${AppRoute.GeneralError}`} />;
  }

  const {
    description,
    lookingFor,
    position,
    seoDescription,
    seoTitle,
    bonusPoints,
    seoImagePath,
    blockquote,
    sendUsMessage,
    roangoLink,
    benefits,
    nextSteps,
  }: OpeningDetails = openings[id];

  return (
    <React.Fragment>
      <SEO
        seoTitle={seoTitle}
        seoDescription={seoDescription}
        imagePath={seoImagePath}
      />

      <section className="opening">
        <header className="opening__heading">
          <div className="wrapper">
            <motion.h1
              className="t-huge t-sans-alt"
              animate={{ opacity: [0, 1], y: [80, 0] }}
              transition={{ ease: 'circOut', duration: 1 }}
            >
              {position}
            </motion.h1>
            <motion.p
              className="t-delta t-sans-alt"
              animate={{ opacity: [0, 1], y: [80, 0] }}
              transition={{ ease: 'circOut', duration: 1, delay: 0.2 }}
            >
              {description}
            </motion.p>
          </div>
        </header>

        <section
          className="opening__expectations"
          ref={openingsExpectationsRef}
        >
          <div className="wrapper">
            <motion.h2
              className="t-beta t-sans-alt"
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: openingsExpectationsRefInView ? 1 : 0,
                y: openingsExpectationsRefInView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
            >
              Our expectations
            </motion.h2>
            <motion.ul
              className="opening__checklist"
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: openingsExpectationsRefInView ? 1 : 0,
                y: openingsExpectationsRefInView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.8 }}
            >
              {lookingFor.map((param, i) => (
                <li key={i}>
                  <div className="opening__checklist__checkmark">
                    <svg
                      width="11"
                      height="10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 6.5L4 9l5.5-8"
                        stroke="#ffffff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <p className="t-epsilon">{param}</p>
                </li>
              ))}
              {bonusPoints && (
                <React.Fragment>
                  {bonusPoints.map((param, i) => (
                    <li key={i}>
                      <div className="opening__checklist__checkmark opening__checklist__checkmark--optional">
                        <svg
                          width="12"
                          height="12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 5H7V1a1 1 0 00-2 0v4H1a1 1 0 000 2h4v4a1 1 0 102 0V7h4a1 1 0 100-2z"
                            fill="#fff"
                          />
                        </svg>
                      </div>
                      <p className="t-epsilon">{param}</p>
                      <p className="t-tiny t-color-light">Bonus points :)</p>
                    </li>
                  ))}
                </React.Fragment>
              )}
            </motion.ul>
          </div>
        </section>

        <section className="opening__section" ref={openingsBenefitsRef}>
          <div className="wrapper">
            <div className="opening__section__title">
              <motion.h2
                className="t-beta t-sans-alt"
                animate={{
                  opacity: openingsBenefitsRefInView ? 1 : 0,
                  y: openingsBenefitsRefInView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6 }}
              >
                {id === 'design-intern'
                  ? 'Start an internship'
                  : 'Benefits of joining the team'}
              </motion.h2>
            </div>

            {benefits?.length ? (
              <CustomBenefits benefits={benefits} />
            ) : (
              <DefaultBenefits />
            )}

            {blockquote && (
              <>
                <div className="opening__blockquote" ref={openingBlockquoteRef}>
                  <motion.div
                    className="opening__blockquote__person t-sans-alt t-delta"
                    animate={{
                      opacity: openingBlockquoteRefInView ? 1 : 0,
                      y: openingBlockquoteRefInView ? 0 : 60,
                    }}
                    transition={{ ease: 'circOut', duration: 0.6 }}
                  >
                    <img
                      className="opening__blockquote__image"
                      loading="lazy"
                      src={require(`assets/images/people/${blockquote.authorImagePath}.jpg`)}
                      alt=""
                    />
                    <div>
                      <p>{blockquote.authorName}</p>
                      <p className="t-color-ultralight">
                        {blockquote.authorPosition}
                      </p>
                    </div>
                  </motion.div>
                  <motion.div
                    className="opening__blockquote__quote"
                    animate={{
                      opacity: openingBlockquoteRefInView ? 1 : 0,
                      y: openingBlockquoteRefInView ? 0 : 60,
                    }}
                    transition={{ ease: 'circOut', duration: 0.6 }}
                  >
                    <BlockquoteSvg className="opening__blockquote__icon" />
                    <blockquote className="t-gamma t-sans-alt">
                      {blockquote.text}
                    </blockquote>
                  </motion.div>
                </div>
              </>
            )}
          </div>
        </section>

        <section
          className="opening__section opening__section--dark"
          ref={openingGetStartedRef}
        >
          <div className="wrapper">
            {id !== 'design-intern' && (
              <>
                <div className="opening__section__title">
                  <motion.h2
                    className="t-beta t-sans-alt"
                    animate={{
                      opacity: openingGetStartedRefInView ? 1 : 0,
                      y: openingGetStartedRefInView ? 0 : 60,
                    }}
                    transition={{ ease: 'circOut', duration: 0.6 }}
                  >
                    Think you got what takes to join the team?
                  </motion.h2>
                </div>
                <motion.ul
                  className="opening__list"
                  animate={{
                    opacity: openingGetStartedRefInView ? 1 : 0,
                    y: openingGetStartedRefInView ? 0 : 60,
                  }}
                  transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
                >
                  {nextSteps?.length ? (
                    <CustomNextSteps nextSteps={nextSteps} />
                  ) : (
                    <DefaultNextSteps />
                  )}
                </motion.ul>
              </>
            )}

            <div className="opening__action" ref={openingCTARef}>
              {roangoLink ? (
                <motion.p
                  className="t-beta t-sans-alt s-bottom--lrg"
                  animate={{
                    opacity: openingCTARefInView ? 1 : 0,
                    y: openingCTARefInView ? 0 : 60,
                  }}
                  transition={{ ease: 'circOut', duration: 0.6 }}
                >
                  Apply for the {position} role on{' '}
                  <a href={roangoLink} className="anchor anchor--negative">
                    Roango
                  </a>{' '}
                  and join our team.
                </motion.p>
              ) : (
                <motion.p
                  className="t-beta t-sans-alt s-bottom--lrg"
                  animate={{
                    opacity: openingCTARefInView ? 1 : 0,
                    y: openingCTARefInView ? 0 : 60,
                  }}
                  transition={{ ease: 'circOut', duration: 0.6 }}
                >
                  {sendUsMessage}{' '}
                  <a
                    href={`mailto:hello@prototyp.digital?subject=I'm applying for an ${position.toLowerCase()} position`}
                    className="anchor anchor--negative"
                  >
                    hello@prototyp.digital
                  </a>
                  .
                </motion.p>
              )}
            </div>
          </div>
        </section>
      </section>
    </React.Fragment>
  );
};
