export enum AppRoute {
  Homepage = '/',
  Blog = 'https://blog.prototyp.digital',
  Services = '/services',
  Company = '/company',
  StarterKit = '/starter-kit',
  Contact = '/contact',
  Openings = '/openings',
  PrivacyPolicy = '/privacy-policy',
  // InterfaceDesigner = '/openings/interface-designer',
  InterfaceDeveloper = '/openings/interface-developer',
  ReactNativeDeveloper = '/openings/react-native-developer',
  QAEngineer = '/openings/qa-engineer',
  AngularDeveloper = '/openings/angular-developer',
  BackendDeveloperNodeJS = '/openings/backend-developer-nodejs',
  BackendDeveloperPHP = '/openings/backend-developer-php',
  SeniorDesigner = '/openings/senior-designer',
  ProjectManager = '/openings/project-manager',
  OpenApplication = '/openings/open-application',
  InvestorsClub = '/investors-club',
  Tolar = '/tolar',
  PWA = '/pwa',
  HappiSeek = '/happiseek',
  GeneralError = '*',
}
