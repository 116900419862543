import { Route, Switch, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';

import { AppRoute } from 'const';
import { HomePage } from 'components/home';
import { ServicesPage } from 'components/services';
import { CompanyPage } from 'components/company';
import { StarterKitPage } from 'components/starter-kit';
import { ContactPage } from 'components/contact';
import { PrivacyPolicyPage } from 'components/privacy-policy';
import { OpeningsPage, OpeningDetailsPage } from 'components/openings';
import {
  InvestorsClubPage,
  PWAPage,
  TolarPage,
  HappiSeekPage,
} from 'components/case-studies';
import { ErrorPage } from 'components/error';

export const Routing: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    /**
     * Scroll to top of the page on every route change
     */
    window.scrollTo(0, 0);

    /**
     * Remove is-locked class from body
     * Used for mobile navigation
     */
    document.body.classList.remove('is-locked');
  }, [location]);

  return (
    <Switch>
      {/** Main */}
      <Route exact path={AppRoute.Homepage}>
        <HomePage />
      </Route>
      <Route exact path={AppRoute.Services}>
        <ServicesPage />
      </Route>
      <Route exact path={AppRoute.Company}>
        <CompanyPage />
      </Route>
      <Route exact path={AppRoute.StarterKit}>
        <StarterKitPage />
      </Route>
      <Route exact path={AppRoute.Contact}>
        <ContactPage />
      </Route>
      <Route exact path={AppRoute.PrivacyPolicy}>
        <PrivacyPolicyPage />
      </Route>

      {/** Case studies */}
      <Route exact path={AppRoute.InvestorsClub}>
        <InvestorsClubPage />
      </Route>
      <Route exact path={AppRoute.Tolar}>
        <TolarPage />
      </Route>
      <Route exact path={AppRoute.PWA}>
        <PWAPage />
      </Route>
      <Route exact path={AppRoute.HappiSeek}>
        <HappiSeekPage />
      </Route>

      {/** Openings */}
      <Route exact path={AppRoute.Openings}>
        <OpeningsPage />
      </Route>
      <Route exact path={`${AppRoute.Openings}/:id`}>
        <OpeningDetailsPage />
      </Route>

      {/** Error */}
      <Route path="*">
        <ErrorPage />
      </Route>
    </Switch>
  );
};
