import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

export const Relay: React.FC = () => {
  const [relayRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  return (
    <section className="relay" ref={relayRef}>
      <div className="wrapper">
        <div className="relay__layout">
          <header className="relay__header">
            <motion.h1
              className="t-sans-alt t-beta"
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 60 }}
              transition={{ ease: 'circOut', duration: 0.6 }}
            >
              Get your daily dose of inspiration on Relay
            </motion.h1>
            <motion.p
              className="t-sans-alt t-delta"
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
            >
              Hand-picked, mind-bending, eye-opening micro blog from various
              creative fields such as design, branding, art, and more...
            </motion.p>
            <motion.div
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 1.0 }}
            >
              <a
                href="https://relay.prototyp.digital"
                className="actionbutton actionbutton--light"
              >
                <svg
                  width="26"
                  height="10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20 1l4 4-4 4M0 5h24" strokeWidth="2" />
                </svg>
                <svg
                  width="26"
                  height="10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20 1l4 4-4 4M0 5h24" strokeWidth="2" />
                </svg>
              </a>
            </motion.div>
          </header>
          <motion.div
            className="relay__visual"
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 60 }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 1.4 }}
          >
            <picture>
              <source
                srcSet={require('assets/images/visuals/relay.webp')}
                type="image/webp"
              />
              <source
                srcSet={require('assets/images/visuals/relay.jpg')}
                type="image/jpeg"
              />
              <img
                loading="lazy"
                src={require('assets/images/visuals/relay.jpg')}
                alt="Relay by PROTOTYP"
              />
            </picture>
          </motion.div>
        </div>
      </div>
    </section>
  );
};
