import React, { useRef } from 'react';

import { SEO, SEOProps } from 'components/shared';
import { useRevealAnimation, useAnimation } from 'hooks';
import { CTA } from 'components/home/fragments/CTA';

export const CaseStudyTemplate: React.FC<SEOProps> = ({
  children,
  seoTitle,
  seoDescription,
  imagePath,
}) => {
  useRevealAnimation('blue');

  const containerRef = useRef(null);
  useAnimation()
    .add(containerRef, {
      opacity: [0, 1],
      duration: 700,
      loop: false,
      elasticity: 0,
      easing: 'easeInSine',
    })
    .animate();

  return (
    <React.Fragment>
      <SEO
        seoTitle={seoTitle}
        seoDescription={seoDescription}
        imagePath={imagePath}
      />

      <article ref={containerRef} className="project">
        {children}
      </article>
      <CTA />
    </React.Fragment>
  );
};
