import React from 'react';
import { Link } from 'react-router-dom';
import InView from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { ReactComponent as BlockquoteSvg } from 'assets/icons/blockquote.svg';
import { AppRoute } from 'const';

import { CaseStudyTemplate } from './CaseStudyTemplate';

export const HappiSeekPage: React.FC = () => {
  return (
    <CaseStudyTemplate
      seoTitle="HappiSeek — PROTOTYP"
      seoDescription="Read how we developed React Native audio app so enjoyable it invokes a relaxing state while using it, with flawless audio playback and smooth animation transitions."
      imagePath="https://prototyp.digital/share/case-studies-share/happiseek-cover.jpg"
    >
      <div className="wrapper">
        <header className="project__header">
          <motion.div
            className="project__header__left"
            initial={{ opacity: 0, y: 60 }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
          >
            <h1 className="t-caption--sm t-sans-alt">HappiSeek</h1>
            <p className="t-alpha t-sans-alt">
              Audio-podcast app that will shift your perception and guide you
              towards happiness.
            </p>
          </motion.div>
          <motion.div
            className="project__header__right"
            initial={{ opacity: 0, y: 60 }}
            animate={{
              opacity: 1,
              y: 0,
            }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
          >
            <a
              href="https://apps.apple.com/us/app/happiseek/id1531605986"
              className="btn btn--xlrg btn--secondary"
            >
              See it live
              <span className="btn__arrow" />
            </a>
          </motion.div>
        </header>
      </div>

      <InView triggerOnce={true} threshold={0.5}>
        {({ ref, inView }) => (
          <motion.picture
            className="project__visual project__visual--fullwidth"
            ref={ref}
            initial={{ opacity: 0, y: 60 }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : 60,
            }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 0.8 }}
          >
            <source
              srcSet={require('assets/images/case-studies/happiseek/happiseek-screen-1.webp')}
              type="image/webp"
            />
            <source
              srcSet={require('assets/images/case-studies/happiseek/happiseek-screen-1.jpg')}
              type="image/jpeg"
            />
            <img
              loading="lazy"
              src={require('assets/images/case-studies/happiseek/happiseek-screen-1.jpg')}
              alt="HappiSeek"
            />
          </motion.picture>
        )}
      </InView>

      <InView triggerOnce={true} threshold={0.3}>
        {({ ref, inView }) => (
          <div className="wrapper">
            <section className="project__section">
              <div className="project__section__layout1">
                <motion.div
                  className="project__section__layout1__left"
                  ref={ref}
                  initial={{ opacity: 0, y: 60 }}
                  animate={{
                    opacity: inView ? 1 : 0,
                    y: inView ? 0 : 60,
                  }}
                  transition={{ ease: 'circOut', duration: 0.6, delay: 0.8 }}
                >
                  <p className="t-gamma t-sans-alt">
                    Soothing design and flawless audio playback make this
                    audio-podcast app a source for awakening your potential.
                  </p>
                </motion.div>
                <motion.div
                  className="project__section__layout1__right"
                  initial={{ opacity: 0, y: 60 }}
                  animate={{
                    opacity: inView ? 1 : 0,
                    y: inView ? 0 : 60,
                  }}
                  transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
                >
                  <p className="t-epsilon t-sans-alt">
                    The client wanted to widen their business expertise with a
                    custom audio-podcast app, making it available for users at
                    all times and places. Our focus was on making the app so
                    enjoyable that it would invoke a relaxing state while using
                    it.
                  </p>
                </motion.div>
              </div>
            </section>
          </div>
        )}
      </InView>

      <InView triggerOnce={true} threshold={0.3}>
        {({ ref, inView }) => (
          <motion.div
            className="wrapper"
            ref={ref}
            initial={{ opacity: 0, y: 60 }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : 60,
            }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
          >
            <picture className="project__visual">
              <source
                srcSet={require('assets/images/case-studies/happiseek/happiseek-screen-2.webp')}
                type="image/webp"
              />
              <source
                srcSet={require('assets/images/case-studies/happiseek/happiseek-screen-2.jpg')}
                type="image/jpeg"
              />
              <img
                loading="lazy"
                src={require('assets/images/case-studies/happiseek/happiseek-screen-2.jpg')}
                alt="HappiSeek"
              />
            </picture>
          </motion.div>
        )}
      </InView>

      <InView triggerOnce={true} threshold={1}>
        {({ ref, inView }) => (
          <div className="wrapper">
            <section className="project__section">
              <motion.div
                className="project__section__heading"
                ref={ref}
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6 }}
              >
                <h1 className="t-beta t-sans-alt">Flawless virtual journey</h1>
              </motion.div>
              <div className="project__section__layout1">
                <motion.div
                  className="project__section__layout1__left"
                  initial={{ opacity: 0, y: 60 }}
                  animate={{
                    opacity: inView ? 1 : 0,
                    y: inView ? 0 : 60,
                  }}
                  transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
                >
                  <p className="t-gamma t-sans-alt">
                    It all started with selecting a colour scheme that will not
                    invoke any uneasy feeling. With that in mind, we carefully
                    choose colours gradients and simple elements that will
                    contribute to the soothing design.
                  </p>
                </motion.div>
                <motion.div
                  className="project__section__layout1__right"
                  initial={{ opacity: 0, y: 60 }}
                  animate={{
                    opacity: inView ? 1 : 0,
                    y: inView ? 0 : 60,
                  }}
                  transition={{ ease: 'circOut', duration: 0.6, delay: 0.8 }}
                >
                  <p className="t-epsilon t-sans-alt">
                    We used default React Native components and capabilities
                    with a minimal dependency on third-party solutions for app
                    development. That allowed us to create a solution for iOS
                    and Android platforms with booth flawless audio playback and
                    smooth animation transitions.
                  </p>
                </motion.div>
              </div>
            </section>
          </div>
        )}
      </InView>

      <InView triggerOnce={true} threshold={0.3}>
        {({ ref, inView }) => (
          <motion.picture
            className="project__visual project__visual--fullwidth"
            ref={ref}
            initial={{ opacity: 0, y: 60 }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : 60,
            }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
          >
            <source
              srcSet={require('assets/images/case-studies/happiseek/happiseek-screen-3.webp')}
              type="image/webp"
            />
            <source
              srcSet={require('assets/images/case-studies/happiseek/happiseek-screen-3.jpg')}
              type="image/jpeg"
            />
            <img
              loading="lazy"
              src={require('assets/images/case-studies/happiseek/happiseek-screen-3.jpg')}
              alt="HappiSeek"
            />
          </motion.picture>
        )}
      </InView>

      <InView triggerOnce={true} threshold={0.6}>
        {({ ref, inView }) => (
          <div className="wrapper">
            <motion.div
              className="project__quote"
              ref={ref}
              initial={{ opacity: 0, y: 60 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 60,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
            >
              <div className="project__quote__icon">
                <BlockquoteSvg />
              </div>
              <blockquote>
                <p className="t-gamma t-sans-alt">
                  PROTOTYP is a team of extraordinarily talented, smart
                  visionaries. They ushered our idea into reality with great
                  aplomb.
                </p>
                <cite className="t-caption--sm t-sans-alt">
                  &mdash;&thinsp;cynthia morgan, co-founder of happiseek
                </cite>
              </blockquote>
            </motion.div>
          </div>
        )}
      </InView>

      <InView triggerOnce={true} threshold={0.3}>
        {({ ref, inView }) => (
          <div className="wrapper">
            <section className="project__meta">
              <div className="project__meta__layout">
                <motion.div
                  ref={ref}
                  initial={{ opacity: 0, y: 60 }}
                  animate={{
                    opacity: inView ? 1 : 0,
                    y: inView ? 0 : 60,
                  }}
                  transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
                >
                  <h2 className="t-delta t-sans-alt">Platforms</h2>
                  <ul className="t-epsilon">
                    <li>iOS</li>
                  </ul>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 60 }}
                  animate={{
                    opacity: inView ? 1 : 0,
                    y: inView ? 0 : 60,
                  }}
                  transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
                >
                  <h2 className="t-delta t-sans-alt">Deliverables</h2>
                  <ul className="t-epsilon">
                    <li>Product design (UI / UX)</li>
                    <li>Product development</li>
                    <li>Architecture & Consulting</li>
                  </ul>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 60 }}
                  animate={{
                    opacity: inView ? 1 : 0,
                    y: inView ? 0 : 60,
                  }}
                  transition={{ ease: 'circOut', duration: 0.6, delay: 0.6 }}
                >
                  <h2 className="t-delta t-sans-alt">Technology</h2>
                  <ul className="t-epsilon">
                    <li>React Native</li>
                    <li>TypeScript</li>
                    <li>Firebase</li>
                  </ul>
                </motion.div>
              </div>
            </section>
          </div>
        )}
      </InView>

      <InView triggerOnce={true} threshold={0.1}>
        {({ ref, inView }) => (
          <motion.picture
            className="project__visual project__visual--fullwidth"
            ref={ref}
            initial={{ opacity: 0, y: 60 }}
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : 60,
            }}
            transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
          >
            <source
              srcSet={require('assets/images/case-studies/happiseek/happiseek-screen-4.webp')}
              type="image/webp"
            />
            <source
              srcSet={require('assets/images/case-studies/happiseek/happiseek-screen-4.jpg')}
              type="image/jpeg"
            />
            <img
              loading="lazy"
              src={require('assets/images/case-studies/happiseek/happiseek-screen-4.jpg')}
              alt="HappiSeek"
            />
          </motion.picture>
        )}
      </InView>

      <div className="project__section__layout3">
        <InView triggerOnce={true}>
          {({ ref, inView }) => (
            <div className="wrapper">
              <div className="project__section__layout3__left">
                <motion.p
                  className="t-delta t-sans-alt t-color-ultralight"
                  ref={ref}
                  initial={{ opacity: 0, y: 60 }}
                  animate={{
                    opacity: inView ? 1 : 0,
                    y: inView ? 0 : 60,
                  }}
                  transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
                >
                  Next case study
                </motion.p>
                <motion.h1
                  className="t-beta t-sans-alt t-negative"
                  initial={{ opacity: 0, y: 60 }}
                  animate={{
                    opacity: inView ? 1 : 0,
                    y: inView ? 0 : 60,
                  }}
                  transition={{ ease: 'circOut', duration: 0.6, delay: 0.4 }}
                >
                  Keep listening to music & podcasts from the browser when on
                  the move
                </motion.h1>
              </div>
              <motion.div
                className="project__section__layout3__right"
                initial={{ opacity: 0, y: 60 }}
                animate={{
                  opacity: inView ? 1 : 0,
                  y: inView ? 0 : 60,
                }}
                transition={{ ease: 'circOut', duration: 0.6, delay: 0.8 }}
              >
                <Link
                  to={AppRoute.PWA}
                  className="actionbutton actionbutton--light"
                >
                  <span>
                    <svg
                      width="26"
                      height="10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 1l4 4-4 4M0 5h24" strokeWidth="2" />
                    </svg>
                    <svg
                      width="26"
                      height="10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 1l4 4-4 4M0 5h24" strokeWidth="2" />
                    </svg>
                  </span>
                </Link>
              </motion.div>
            </div>
          )}
        </InView>
        <InView triggerOnce={true} threshold={0.3}>
          {({ ref, inView }) => (
            <motion.div
              className="wrapper"
              ref={ref}
              initial={{ opacity: 0, y: 20 }}
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 20,
              }}
              transition={{ ease: 'circOut', duration: 0.6, delay: 0.2 }}
            >
              <picture className="project__visual">
                <source
                  srcSet={require('assets/images/case-studies/happiseek/happiseek-next-case-study.webp')}
                  type="image/webp"
                />
                <source
                  srcSet={require('assets/images/case-studies/happiseek/happiseek-next-case-study.png')}
                  type="image/jpeg"
                />
                <img
                  loading="lazy"
                  src={require('assets/images/case-studies/happiseek/happiseek-next-case-study.png')}
                  alt="HappiSeek"
                />
              </picture>
            </motion.div>
          )}
        </InView>
      </div>
    </CaseStudyTemplate>
  );
};
