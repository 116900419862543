import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { GhostPost } from '.';

export const ArticleItem: React.FC<GhostPost> = ({ title, authors, url }) => {
  const [prototypedArticleRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  return (
    <motion.article
      ref={prototypedArticleRef}
      className="prototyped__article"
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 40 }}
      transition={{ ease: 'circOut', duration: 0.6 }}
    >
      <header className="prototyped__article__heading">
        <div className="prototyped__article__author">
          <a
            href={authors && authors[0].url}
            className="t-zeta t-opaque-50 t-negative prototyped__article__author__link"
          >
            By {authors && authors[0].name}
          </a>
        </div>
        <a href={url} className="prototyped__article__title">
          <h1
            className="t-delta t-sans-alt"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </a>
      </header>
    </motion.article>
  );
};
