import React from 'react';

import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

export const OSC: React.FC = () => {
  const [oscRef, inView] = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px',
  });

  return (
    <section className="osc" ref={oscRef}>
      <div className="wrapper">
        <div className="osc__layout">
          <header className="osc__header">
            <motion.h2
              className="t-beta t-sans-alt"
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 80,
              }}
              transition={{ ease: 'circOut', duration: 0.6 }}
            >
              Proud member of Osijek Software City
            </motion.h2>
            <motion.p
              className="t-epsilon t-sans-alt"
              animate={{
                opacity: inView ? 1 : 0,
                y: inView ? 0 : 40,
              }}
              transition={{ ease: 'circOut', duration: 0.4, delay: 0.4 }}
            >
              PROTOTYP is a member of a thriving community{' '}
              <a href="http://softwarecity.hr/" className="anchor anchor--text">
                Osijek Software City.
              </a>{' '}
              Building our community, sharing knowledge and enabling others to
              do great work is our drive. We have participated in many notable
              community projects.
            </motion.p>
          </header>
          <motion.div
            className="osc__content"
            animate={{
              opacity: inView ? 1 : 0,
              y: inView ? 0 : 80,
            }}
            transition={{ ease: 'circOut', duration: 0.8, delay: 0.6 }}
          >
            <ul className="osc__list t-delta t-sans-alt">
              <li className="osc__list__item">
                <span>KulenDayz</span>
                <span className="t-color-light">Conference</span>
              </li>
              <li className="osc__list__item">
                <span>CodeCamp</span>
                <span className="t-color-light">Conference</span>
              </li>
              <li className="osc__list__item">
                <span>Design Talks</span>
                <span className="t-color-light">Design event</span>
              </li>
              <li className="osc__list__item">
                <span>Design Initiative Osijek (DIO)</span>
                <span className="t-color-light">Local Initiative</span>
              </li>
              <li className="osc__list__item">
                <span>Digital Design Academy</span>
                <span className="t-color-light">Learning programme</span>
              </li>
              <li className="osc__list__item">
                <span>Design Interaction Design</span>
                <span className="t-color-light">Learning programme</span>
              </li>
            </ul>
          </motion.div>
        </div>
      </div>
    </section>
  );
};
